import { usePlatform } from "@/hooks/usePlatform";
import { ListItem } from "./ListItem";
import { useInView } from "react-intersection-observer";
import {
  ResponseGetRatingCars,
  ResponseGetRatingCarsMe,
  ResponseGetRatingEarnings,
  ResponseGetRatingEarningsMe,
} from "@/api";

export function List(p: {
  currentUserId: number | undefined;
  data: ResponseGetRatingCars[] | ResponseGetRatingEarnings[] | undefined;
  myRankData: ResponseGetRatingCarsMe | ResponseGetRatingEarningsMe | undefined;
  isMeInTopRating: boolean;
  isCarsStatus: boolean;
}) {
  const { isApplePlatform } = usePlatform();
  const [ref, inView, entry] = useInView({
    threshold: 0.9,
    rootMargin: "0px 0px -5px 0px",
  });

  return (
    <>
      <ul className={"relative"}>
        {p.data?.map((user, index) => {
          const isCurrentUser = user.id === p.currentUserId;
          return (
            <ListItem
              key={user.id}
              position={index + 1}
              user={user}
              isCurrentUser={isCurrentUser}
              inViewRef={isCurrentUser ? ref : null}
              isCarsStatus={p.isCarsStatus}
              isApplePlatform={isApplePlatform}
            />
          );
        })}

        {p.myRankData && !p.isMeInTopRating && (
          <ListItem
            position={p.myRankData.rank}
            user={p.myRankData}
            isCurrentUser={true}
            inViewRef={ref}
            isCarsStatus={p.isCarsStatus}
            isApplePlatform={isApplePlatform}
          />
        )}
        {!inView &&
          entry?.boundingClientRect &&
          entry?.boundingClientRect.y > 0 &&
          p.myRankData && (
            <ListItem
              position={p.myRankData.rank}
              user={p.myRankData}
              isCurrentUser={true}
              inViewRef={null}
              isCarsStatus={p.isCarsStatus}
              isApplePlatform={isApplePlatform}
              isHighlighted={true}
            />
          )}
      </ul>
    </>
  );
}
