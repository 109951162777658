import { useState } from "react";
import { useTranslation } from "react-i18next";
import { HeaderScreen } from "../HeaderScreen";
import { BalanceField } from "@/components/BalanceField";
import { Tabs } from "@/components/ui/Tabs";
import { List } from "./List";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { apiQueries } from "@/api/queries";
import { Button } from "@/components/ui/Button";
import { PaymentType } from "@/api";

const SIZE = 25;

export function TransactionsPage() {
  const { t } = useTranslation("common");
  const [size, setSize] = useState(SIZE);
  const [status, setStatus] = useState<PaymentType>("deposit");

  const tabs = [
    { label: t("transactions.refill"), id: "deposit" },
    { label: t("transactions.transfer"), id: "withdrawal" },
  ];

  const transactionsQuery = useQuery({
    ...apiQueries.getTransactions({
      page: 1,
      size,
      ordering: "-create_date",
      type: status,
    }),
    refetchOnMount: true,
    placeholderData: keepPreviousData,
  });

  const { data } = transactionsQuery;

  return (
    <div className="mb-2 h-full flex-grow flex flex-col">
      <HeaderScreen isBack title={t("transactions.title")}>
        <BalanceField />
      </HeaderScreen>

      <div className={"flex flex-grow flex-col pb-6"}>
        <Tabs
          tabs={tabs}
          activeTab={status}
          onChange={(tab) => {
            if (tab === "deposit" || tab === "withdrawal") {
              setStatus(tab);
              setSize(SIZE);
            }
          }}
        />
        {data?.results.length !== 0 && (
          <List data={data?.results ?? []} type={status} />
        )}
        {data?.results.length === 0 && (
          <div className={"flex-grow flex items-center justify-center"}>
            <div className={"text-gray-middle text-center"}>
              {t("transactions.noTransactions")}
            </div>
          </div>
        )}

        {data?.next && (
          <Button
            onClick={() => {
              setSize(size + SIZE);
            }}
            className="w-full bg-black-footer text-yellow-primary border border-yellow-primary py-2"
          >
            {t("transactions.showMore")}
          </Button>
        )}
      </div>
    </div>
  );
}
