/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import { SDKProvider } from "@telegram-apps/sdk-react";
import { type FC, useEffect, Suspense, useMemo } from "react";

import { App } from "@/components/App.tsx";
import { ErrorBoundary } from "@/components/ErrorBoundary.tsx";
import { isMainBranch } from "@/variables";
import { THEME, TonConnectUIProvider } from "@tonconnect/ui-react";
import { TonClientProvider } from "../context/ton-client-context";

const ErrorBoundaryError: FC<{ error: unknown }> = ({ error }) => (
  <div>
    <p>An unhandled error occurred:</p>
    <blockquote>
      <code>
        {error instanceof Error
          ? error.message
          : typeof error === "string"
          ? error
          : JSON.stringify(error)}
      </code>
    </blockquote>
  </div>
);

export const debug = import.meta.env.VITE_DEBUG === "true";

const Inner: FC = () => {
  const manifestUrl = useMemo(() => {
    return new URL(
      debug ? "tonconnect-manifest-dev.json" : "tonconnect-manifest.json",
      window.location.href
    ).toString();
  }, []);

  // Enable debug mode to see all the methods sent and events received.
  useEffect(() => {
    if (debug) {
      import("eruda").then((lib) => lib.default.init());
    }
  }, [debug]);

  return (
    <TonConnectUIProvider
      manifestUrl={manifestUrl}
      uiPreferences={{
        theme: THEME.DARK,
      }}
    >
      <TonClientProvider>
        <SDKProvider acceptCustomStyles debug={false}>
          <App />
        </SDKProvider>
      </TonClientProvider>
    </TonConnectUIProvider>
  );
};

export const Root: FC = () => {
  useEffect(() => {
    if (isMainBranch) {
      // <!-- Yandex.Metrika counter -->

      // eslint-disable-next-line prefer-rest-params
      (function (m, e, t, r, i, k, a) {
        m[i] =
          m[i] ||
          function () {
            // eslint-disable-next-line prefer-rest-params
            (m[i].a = m[i].a || []).push(arguments);
          };
        m[i].l = 1 * new Date();
        // eslint-disable-next-line no-var
        for (var j = 0; j < document.scripts.length; j++) {
          if (document.scripts[j].src === r) {
            return;
          }
        }
        (k = e.createElement(t)),
          (a = e.getElementsByTagName(t)[0]),
          (k.async = 1),
          (k.src = r),
          a.parentNode.insertBefore(k, a);
      })(
        // eslint-disable-next-line no-unexpected-multiline
        window,
        document,
        "script",
        "https://mc.yandex.ru/metrika/tag.js",
        "ym"
      );

      ym(98745933, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        trackHash: true,
      });

      // <!-- Google tag (gtag.js) -->

      const script = document.createElement("script");
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-6M25D55KB1";
      script.async = true;
      document.body.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      // eslint-disable-next-line no-inner-declarations, prefer-rest-params
      function gtag() {
        // eslint-disable-next-line prefer-rest-params
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-6M25D55KB1");
    }
  }, [isMainBranch]);

  return (
    <Suspense fallback="Loading...">
      <ErrorBoundary fallback={ErrorBoundaryError}>
        <Inner />
      </ErrorBoundary>
    </Suspense>
  );
};
