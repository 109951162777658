import { useInView } from "react-intersection-observer";
import { carTimer, ItemCarService } from "./ItemCarService";
import { Dispatch, SetStateAction, useEffect } from "react";
import { CarServiceItem } from "@/api";
import { SelectedCar } from "./CarService";
import { useImagePreload } from "@/hooks/useImagePreload";

type CarsListProps = {
  serviceCars: {
    results: CarServiceItem[];
    count: number;
    next: string | null;
    previous: string | null;
  };
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isFetching: boolean;
  setSelectedCar: Dispatch<SetStateAction<SelectedCar | null>>;
};

export function CarsList({
  serviceCars,
  fetchNextPage,
  hasNextPage,
  isFetching,
  setSelectedCar,
}: CarsListProps) {
  const { ref, inView } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (inView && hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [hasNextPage, inView]);

  useImagePreload(carTimer, { enabled: true });

  return (
    <ul className="flex flex-col gap-2">
      {serviceCars.results.map((item) => (
        <ItemCarService
          idItem={item.id}
          key={item.id}
          title={item.car.title}
          sequenceId={item.sequence_id}
          cover={item.cover.file}
          breakdown={item.last_breakdown}
          setSelectedCar={setSelectedCar}
        />
      ))}
      <li ref={ref} />
    </ul>
  );
}
