import { useIntegration } from "@telegram-apps/react-router-integration";
import {
  bindMiniAppCSSVars,
  bindThemeParamsCSSVars,
  bindViewportCSSVars,
  initNavigator,
  useBackButton,
  useLaunchParams,
  useMiniApp,
  useThemeParams,
  useViewport,
} from "@telegram-apps/sdk-react";
import { AppRoot } from "@telegram-apps/telegram-ui";
import { type FC, useEffect, useMemo, useState } from "react";
import { Router, useRoutes } from "react-router-dom";

import { routes } from "@/navigation/routes.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { saveToken } from "@/api/apiUtils";
import { ROUTE } from "@/config/navigation";
import { SnackbarProvider } from "./ui/SnackBar/SnackbarContext";
import { useCheckDetectedLang } from "@/localization/useCheckDetectedLang";
import { Onboarding } from "./Onboarding";
import { usePlatform } from "@/hooks/usePlatform";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: true,
      retry: 3,
    },
  },
});

export const App: FC = () => {
  const lp = useLaunchParams();
  const miniApp = useMiniApp();
  const themeParams = useThemeParams();
  const viewport = useViewport();
  const backBtn = useBackButton();
  const { isApplePlatform } = usePlatform();

  useCheckDetectedLang();

  useEffect(() => {
    miniApp.setHeaderColor("#242424");
    miniApp.setBgColor("#242424");

    saveToken(lp.initDataRaw!);
  }, []);

  function preventCollapse() {
    if (window.scrollY === 0) {
      window.scrollTo(0, 1);
    }
  }

  useEffect(() => {
    return bindMiniAppCSSVars(miniApp, themeParams);
  }, [miniApp, themeParams]);

  useEffect(() => {
    return bindThemeParamsCSSVars(themeParams);
  }, [themeParams]);

  useEffect(() => {
    viewport && viewport.expand();
  }, [viewport]);

  useEffect(() => {
    // viewport?.on("change:height", () => viewport?.expand());
    return viewport && bindViewportCSSVars(viewport);
  }, [viewport]);

  // useEffect(() => {
  //   if (viewport?.isExpanded) {
  //     setAppleStyles({
  //       height: "calc(100dvh + 3px)",
  //     });
  //   }
  // }, [viewport?.isExpanded]);

  // Create a new application navigator and attach it to the browser history, so it could modify
  // it and listen to its changes.
  const navigator = useMemo(() => initNavigator("app-navigation-state"), []);
  const [location, reactNavigator] = useIntegration(navigator);
  // console.log("🚀 ~ location:", location, reactNavigator)

  // Don't forget to attach the navigator to allow it to control the BackButton state as well
  // as browser history.
  useEffect(() => {
    navigator.attach();
    return () => navigator.detach();
  }, [navigator]);

  useEffect(() => {
    backBtn.hide();
    location.pathname === "/" && reactNavigator.replace(ROUTE.MARKET);
  }, [location.pathname]);

  const [isShowLoader, setIsShowLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShowLoader(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isShowLoader && <Onboarding />}
      <AppRoot
        style={{
          //@ts-expect-error no types for tg vars
          "--tg-theme-bg-color": "#242424",
          "--tg-theme-text-color": "#FEFEFE",
          // "--tgui--surface_dark": "#242424",
          "--tgui--font-family": "Inter, sans-serif",
        }}
        // for prevent collapse on swipe down
        onTouchStart={isApplePlatform ? undefined : preventCollapse}
        appearance={"dark"}
        platform={["macos", "ios"].includes(lp.platform) ? "ios" : "base"}
      >
        <QueryClientProvider client={queryClient}>
          <Router location={location} navigator={reactNavigator}>
            <SnackbarProvider>
              {/* for prevent collapse on swipe down */}
              <div
                style={
                  isApplePlatform
                    ? {
                        height: "calc(100dvh + 50px)",
                      }
                    : undefined
                }
              >
                <Route />
              </div>
            </SnackbarProvider>
          </Router>
        </QueryClientProvider>
      </AppRoot>
    </>
  );
};

function Route() {
  return useRoutes(routes);
}
