import { useTranslation } from "react-i18next";
import { ListItem } from "./ListItem";
import { PaymentType, ResponseGetTransactions } from "@/api";
import { useEffect, useState } from "react";

type RegroupedData = {
  created_at: string;
  transactions: ResponseGetTransactions[];
}[];

export function List(p: {
  data: ResponseGetTransactions[];
  type: PaymentType;
}) {
  const {
    i18n: { language },
  } = useTranslation("common");

  const [regroupedData, setRegroupedData] = useState<RegroupedData>([]);

  useEffect(() => {
    if (p.data) {
      const newData: RegroupedData = Object.entries(
        p.data.reduce(
          (acc: { [key: string]: ResponseGetTransactions[] }, item) => {
            const date = item.created_at.split("T")[0];
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(item);
            return acc;
          },
          {}
        )
      ).map(([key, value]) => ({ created_at: key, transactions: value }));

      setRegroupedData(newData);
    }
  }, [p.data]);

  return (
    <ul className="mt-4">
      {regroupedData.map((item) => (
        <li key={item.created_at} className="mb-6">
          <span className="text-white-65 text-sm font-bold">
            {new Date(item.created_at).toLocaleDateString(language, {
              day: "2-digit",
              month: "long",
              year: "numeric",
            })}
          </span>
          <ul className="mt-1.5 flex flex-col gap-2">
            {item.transactions.map((_item) => (
              <ListItem data={_item} key={_item.id} type={p.type} />
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
}
