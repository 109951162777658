import { Address, toNano } from "@ton/ton";

export const TXY = "TXY";
export const USDT = "USDT";

export const numberInputRegex = /^[0-9.,]*$/;

export const STICKER_INCREASE_PERCENT = import.meta.env
  .VITE_STICKER_INCREASE_PERCENT;
export const REQUEST_COMMISSION = import.meta.env.VITE_REQUEST_COMMISSION

export const SECONDS_IN_HOUR = 60 * 60;
export const SECONDS_IN_MINUTE = 60;

export const TERMS_OF_USE = 'https://drive.google.com/drive/folders/1iY5JD0QhVJassIt8NpjvdZVceNAnXPlh?usp=sharing'
export const PRIVACY_POLICY = 'https://drive.google.com/drive/folders/12zECHwwv2bAOPmcm4fkBd2ILlbixv4N6?usp=sharing'

export const INSTRUCTIONS = {
  en: "https://teletype.in/@taxiflygame/InstructionsforTopUp",
  ua: "https://teletype.in/@taxifly/instuction_ua",
  ru: "https://teletype.in/@taxiflygame/InstructionsforTopUpru"
}

export const isMainBranch = import.meta.env.VITE_BRANCH === 'main'

export const JETTON_TRANSFER_GAS_FEES = toNano("0.038");

export const USDT_MASTER_ADDRESS = Address.parse(
  import.meta.env.VITE_USDT_MASTER_ADDRESS
  // "kQD0GKBM8ZbryVk2aESmzfU6b9b_8era_IkvBSELujFZPsyy"
); // The master address of the USDT. // todo: for prod - `EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs`

export const INVOICE_WALLET_ADDRESS = Address.parse(
  import.meta.env.VITE_INVOICE_WALLET_ADDRESS
); // The address of the wallet where USDT will be received upon payment.
// Important: This should be the address of the usual TON wallet, not the USDT jetton wallet.

export const COMPETITION_CAR_NAME = "Mercedes E class"