import { SideBar } from "@/components/sideBar/SideBar";
import { Button } from "@/components/ui/Button";
import { Input } from "@/components/ui/form/Input";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { apiMutations, apiQueries } from "@/api/queries";
import { numberInputRegex, REQUEST_COMMISSION, TXY, USDT } from "@/variables";
import { api } from "@/api";
import { getMinTransferInTXY } from "@/utils";
import { Spinner } from "@/components/ui/Spinner";
import { TransactionResult } from "./TransactionResult";

export function TransferForm() {
  const { t } = useTranslation("common");

  const { data: currentRate } = useQuery({
    ...apiQueries.getCurrentRate,
    refetchOnMount: false,
  });
  const { data: userData, refetch: refetchUserData } = useQuery({
    ...apiQueries.getProfile,
    refetchOnMount: false,
  });

  const [wallet, setWallet] = useState("");
  const [amount, setAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState<string | null>(null);
  const [debounceValue, setDebounceValue] = useState("");

  const [isTransferInProcess, setIsTransferInProcess] = useState(false);
  const [isTotalAmountLoading, setIsTotalAmountLoading] = useState(false);

  const MIN_IN_TXY = getMinTransferInTXY(Number(currentRate?.usdt_per_txy));

  const amountError =
    Number(userData?.balance) < parseFloat(amount)
      ? t("balance.transfer.notEnoughBalance")
      : amount && parseFloat(amount) < MIN_IN_TXY
      ? `${t("balance.transfer.min")} ${
          MIN_IN_TXY /* переведено из USDT по курсу в TXY*/
        } ${TXY}`
      : "";

  const onChangeWallet = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setWallet(value);
  };

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (numberInputRegex.test(value)) {
      const formattedValue = value.replace(",", ".");

      const hasDot = (formattedValue.match(/\./g) || []).length;

      if (hasDot > 1) {
        return;
      }

      setAmount(formattedValue);
    }
  };

  const requestWithdrawal = useMutation({
    ...apiMutations.requestWithdrawal,
    onSuccess: () => {
      setIsTransferInProcess(true);
      refetchUserData();
      setWallet("");
      setAmount("");
    },
  });

  const onSubmit = () => {
    requestWithdrawal.mutateAsync({
      pay_currency: USDT,
      amount_in_game_currency: amount,
      wallet_address: wallet,
    });
  };

  useEffect(() => {
    setIsTotalAmountLoading(true);
    const timeout = setTimeout(() => {
      setDebounceValue(amount);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [amount]);

  useEffect(() => {
    if (debounceValue) {
      api.convertTXYForRequest({ txy: debounceValue }).then((response) => {
        setTotalAmount(response.usdt);
        setIsTotalAmountLoading(false);
      });
    } else {
      setIsTotalAmountLoading(false);
      setTotalAmount(null);
    }
  }, [debounceValue]);

  return (
    <>
      <div className="mt-2">
        <p className="text-gray-middle text-sm mb-4">
          {t("balance.transfer.subtitle")}
        </p>

        <Input
          className="w-full mb-4"
          label={t("balance.transfer.setWalletAddress")}
          placeholder={t("balance.transfer.setAddress")}
          value={wallet}
          onChange={onChangeWallet}
        />

        <Input
          className="w-full"
          label={t("balance.transfer.setAmountForTransfer")}
          placeholder={t("balance.transfer.setAmount")}
          value={amount}
          onChange={onChangeAmount}
          error={amountError}
          helperText={`${t("balance.transfer.min")} ${
            MIN_IN_TXY /* переведено из USDT по курсу в TXY*/
          } ${TXY}`}
        />

        <div className="h-[1px] w-full bg-white-light opacity-20 my-5" />

        <div>
          <p className="text-sm">
            {t("balance.transfer.finalAmount", { percent: REQUEST_COMMISSION })}
          </p>
          <p className="text-2xl font-bold mt-1.5">
            {isTotalAmountLoading ? (
              <Spinner size="small" variant="primary" />
            ) : (
              `${totalAmount && amount ? totalAmount : 0} ${USDT}`
            )}
          </p>
        </div>

        <Button
          className="fixed bottom-0 left-0 right-0 mx-layout my-6 flex justify-center"
          disabled={
            Boolean(amountError) ||
            ((!wallet || !amount || !totalAmount || isTotalAmountLoading) &&
              !amountError)
          }
          onClick={onSubmit}
        >
          {requestWithdrawal.isPending ? (
            <Spinner size="small" variant="dark" />
          ) : (
            t("balance.transfer.title")
          )}
        </Button>
      </div>

      <SideBar
        isOpen={isTransferInProcess}
        setIsOpen={setIsTransferInProcess}
        withoutPaddings
        withCross={false}
      >
        <TransactionResult
          setIsOpen={setIsTransferInProcess}
          type={"withdrawal"}
        />
      </SideBar>
    </>
  );
}
