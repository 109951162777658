export const CopySvg = ({ isGray = false }: { isGray?: boolean }) => (
  <svg
    className="size-4"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3337 9.13734V12.1123C11.3337 14.5915 10.342 15.5832 7.86283 15.5832H4.88783C2.40866 15.5832 1.41699 14.5915 1.41699 12.1123V9.13734C1.41699 6.65817 2.40866 5.6665 4.88783 5.6665H7.86283C10.342 5.6665 11.3337 6.65817 11.3337 9.13734Z"
      stroke={isGray ? "#8D8D8D" : "#FFD304"}
      strokeWidth="1.0625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5837 4.88734V7.86234C15.5837 10.3415 14.592 11.3332 12.1128 11.3332H11.3337V9.13734C11.3337 6.65817 10.342 5.6665 7.86283 5.6665H5.66699V4.88734C5.66699 2.40817 6.65866 1.4165 9.13783 1.4165H12.1128C14.592 1.4165 15.5837 2.40817 15.5837 4.88734Z"
      stroke={isGray ? "#8D8D8D" : "#FFD304"}
      strokeWidth="1.0625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
