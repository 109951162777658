import { CopySvg } from "@/components/ui/CopySvg";
import { useSnackbar } from "@/components/ui/SnackBar/SnackbarContext";
import { useTranslation } from "react-i18next";

export const LinkReferral = ({ link }: { link: string }) => {
  const { t } = useTranslation("common");

  const { setShowWithProps } = useSnackbar();
  const saveLink = () => {
    setShowWithProps({
      children: t("refs.copy"),
      isSuccess: true,
    });
    navigator.clipboard.writeText(link);
  };

  return (
    <div
      className={"flex items-center gap-2 cursor-pointer"}
      onClick={saveLink}
    >
      <p className={"underline text-yellow-primary"}>{link}</p>
      <CopySvg />
    </div>
  );
};
