import { Dispatch, SetStateAction } from "react";
import style from "./RadioInput.module.css";
import { PayCurrency } from "@/api";

export function RadioInput({
  item,
  isChecked,
  onChange,
}: {
  item: { value: PayCurrency; label: string };
  isChecked: boolean;
  onChange: Dispatch<SetStateAction<PayCurrency>>
}) {
  return (
    <div className="text-sm">
      <input
        type="radio"
        className={`${style.input} hidden`}
        id={item.value}
        value={item.value}
        checked={isChecked}
        onChange={(e) => {
          onChange(e.target.value as PayCurrency);
        }}
      />
      <label
        className={`${style.label} flex items-center gap-2 px-3.5 py-3 rounded-12 cursor-pointer text-white-light bg-gray-dark-input  placeholder:text-gray-middle focus-visible:outline-0 relative`}
        htmlFor={item.value}
      >
        <span className="relative flex items-center justify-center border-[2px] border-gray-middle min-w-5 w-5 h-5 rounded-full" />
        {item.label}
      </label>
    </div>
  );
}
