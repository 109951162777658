import { Link } from "@/components/Link/Link";
import { Button } from "@/components/ui/Button";
import { Input } from "@/components/ui/form/Input";
import { RadioInput } from "@/components/ui/form/RadioInput";
import { Spinner } from "@/components/ui/Spinner";
import { ROUTE } from "@/config/navigation";
import { useTonConnect } from "@/hooks/useTonConnect";
import {
  INVOICE_WALLET_ADDRESS,
  JETTON_TRANSFER_GAS_FEES,
  numberInputRegex,
  TXY,
  USDT,
  USDT_MASTER_ADDRESS,
} from "@/variables";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Address, toNano } from "@ton/core";
import { JettonMaster } from "@ton/ton";
import { JettonWallet } from "./JettonWallet";
import { api, PayCurrency } from "@/api";
import { SideBar } from "@/components/sideBar/SideBar";
import { TransactionResult } from "./TransactionResult";
import { useSnackbar } from "@/components/ui/SnackBar/SnackbarContext";

const radioInputs: { value: PayCurrency; label: string }[] = [
  {
    value: "USDT",
    label: "USDT (TON)",
  },
];

export const calculateUsdtAmount = (usdCents: number) =>
  BigInt(usdCents * 10000);

export function RefillForm() {
  const { t } = useTranslation("common");
  const { setShowWithProps } = useSnackbar();

  // todo: добавить проверку - больше или равно 1
  const [amount, setAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState<string | null>(null);
  const [debounceValue, setDebounceValue] = useState("");

  const [currency, setCurrency] = useState(radioInputs[0].value);
  const [error, setError] = useState("");

  const [isTotalAmountLoading, setIsTotalAmountLoading] = useState(false);
  const [isTransactionInProcess, setIsTransactionInProcess] = useState(false);
  const [isTransactionInProcessOpen, setIsTransactionInProcessOpen] =
    useState(false);

  const { sender, walletAddress, tonClient } = useTonConnect();

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    const value = e.target.value;

    if (numberInputRegex.test(value)) {
      const formattedValue = value.replace(",", ".");

      const hasDot = (formattedValue.match(/\./g) || []).length;

      if (hasDot > 1) {
        return;
      }

      setAmount(formattedValue);
    }
  };

  useEffect(() => {
    setIsTotalAmountLoading(true);
    const timeout = setTimeout(() => {
      setDebounceValue(amount);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [amount]);

  useEffect(() => {
    if (debounceValue) {
      api.convertTXYForDeposit({ txy: debounceValue }).then((response) => {
        setTotalAmount(response.usdt);
        setIsTotalAmountLoading(false);
      });
    } else {
      setIsTotalAmountLoading(false);
      setTotalAmount(null);
    }
  }, [debounceValue]);

  const onSubmit = async () => {
    setIsTransactionInProcess(true);

    let transaction;

    try {
      transaction = await api.createTransaction({
        pay_currency: currency,
        amount_in_game_currency: amount,
      });
    } catch (error) {
      setIsTransactionInProcess(false);
      setShowWithProps({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        children: error?.errors?.[0]?.detail,
        isError: true,
        duration: 7000,
      });
    }

    if (transaction) {
      await sendTransactionJetton(
        toNano(Number(transaction.amount_in_pay_currency) / 1000),
        transaction.uuid || walletAddress?.toString().slice(0, 5) || "aboba"
      );
    }
  };

  const sendTransactionJetton = useCallback(
    async (amount: bigint, comment: string) => {
      try {
        if (!tonClient || !walletAddress) return;

        const jettonMaster = tonClient.open(
          JettonMaster.create(USDT_MASTER_ADDRESS)
          // ABOBA мастер
          // JettonMaster.create(
          //   Address.parse("kQCGkPRiUrNP8KGLLIBoLTeshMnkxmj7esXE-egc1YHUySVt")
          // )
        );

        const usersUsdtAddress = await jettonMaster.getWalletAddress(
          walletAddress as Address
        );

        const jettonWallet = tonClient.open(
          JettonWallet.createFromAddress(usersUsdtAddress)
        );

        await jettonWallet.sendTransfer(sender, {
          fwdAmount: 1n,
          comment,
          jettonAmount: amount,
          toAddress: INVOICE_WALLET_ADDRESS,
          value: JETTON_TRANSFER_GAS_FEES,
        });

        setIsTransactionInProcessOpen(true);

        setAmount("");
        setTotalAmount(null);
        setDebounceValue("");
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (error?.message.toLowerCase().includes("context")) {
          setIsTransactionInProcessOpen(true);

          setAmount("");
          setTotalAmount(null);
          setDebounceValue("");
        } else {
          setShowWithProps({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            children: error?.stack,
            isError: true,
          });
        }
      } finally {
        setIsTransactionInProcess(false);
      }
    },
    [tonClient, walletAddress, sender]
  );

  return (
    <>
      <div className="mt-2">
        <p className="text-gray-middle text-sm mb-4">
          {t("balance.refill.subtitle")}
        </p>

        {!walletAddress && (
          <Link
            to={ROUTE.WALLET}
            className="block text-black-dark bg-yellow-primary mb-4 rounded-8 border py-2 px-3 text-center"
          >
            {t("balance.refill.addWallet")}
          </Link>
        )}

        <Input
          className="w-full"
          label={`${t("balance.refill.setAmount")} ${TXY}`}
          placeholder={t("balance.refill.setAmount")}
          value={amount}
          onChange={onChangeAmount}
        />

        <div className="h-[1px] w-full bg-white-light opacity-20 my-5" />

        <div>
          <p className="text-sm">{t("balance.refill.chooseCurrency")}</p>
          <ul className="flex flex-col gap-3 mt-2">
            {radioInputs.map((item) => (
              <li key={item.value}>
                <RadioInput
                  item={item}
                  onChange={setCurrency}
                  isChecked={item.value === currency}
                />
              </li>
            ))}
          </ul>
        </div>
        <div className="h-[1px] w-full bg-white-light opacity-20 my-5" />

        <div>
          <p className="text-sm">
            {t("balance.refill.finalAmount", { currency: USDT })}
          </p>
          <p className="text-2xl font-bold mt-1.5">
            {isTotalAmountLoading ? (
              <Spinner size="small" variant="primary" />
            ) : (
              `${totalAmount && amount ? totalAmount : 0} ${USDT}`
            )}
          </p>
        </div>

        <Button
          className="fixed bottom-0 left-0 right-0 mx-layout my-6 flex justify-center"
          disabled={
            (!amount ||
              !currency ||
              !walletAddress ||
              !totalAmount ||
              isTotalAmountLoading ||
              isTransactionInProcess) &&
            !error
          }
          onClick={onSubmit}
        >
          {isTransactionInProcess ? (
            <Spinner size="small" />
          ) : (
            t("balance.refill.title")
          )}
        </Button>
      </div>

      <SideBar
        isOpen={isTransactionInProcessOpen}
        setIsOpen={setIsTransactionInProcessOpen}
        withoutPaddings
        withCross={false}
      >
        <TransactionResult
          setIsOpen={setIsTransactionInProcessOpen}
          type={"deposit"}
        />
      </SideBar>
    </>
  );
}
