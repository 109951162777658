import { Card } from "@/components/cards/Card/Card";
import { imgTire } from "@/assets";
import { Button } from "@/components/ui/Button";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import carTimer from "@/assets/car_repair.gif";
import { Time } from "./Time";
import { SelectedCar } from "./CarService";
import { cn } from "@/utils";
import { BreakDown } from "@/api";
import { Lang } from "@/localization/localizationConfig";

export { carTimer };

type ItemCarServiceProps = {
  title: string;
  sequenceId: number;
  cover: string;
  breakdown: BreakDown;
  idItem: number;
  setSelectedCar: Dispatch<SetStateAction<SelectedCar | null>>;
};

export type StatusCar = "break" | "timer" | "completed";

const getStatusCar = (repairedAt: Date | undefined | null): StatusCar => {
  if (!repairedAt) {
    return "break";
  }
  if (new Date(repairedAt).getTime() < Date.now()) {
    return "completed";
  } else {
    return "timer";
  }
};

export const ItemCarService = ({
  breakdown,
  sequenceId,
  title,
  cover,
  setSelectedCar,
  idItem,
}: ItemCarServiceProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [statusCar, setStatusCar] = useState<StatusCar>(() =>
    getStatusCar(breakdown.repaired_at)
  );

  useEffect(() => {
    setStatusCar(getStatusCar(breakdown.repaired_at));
  }, [breakdown]);

  const updateStatusCar = () =>
    setStatusCar(getStatusCar(breakdown.repaired_at));

  return (
    <li
      className={cn(
        "relative overflow-hidden rounded-10 border border-black-dark-card flex flex-col",
        statusCar === "timer" && " bg-black-dark p-1.5"
      )}
    >
      {statusCar !== "timer" && (
        <h2 className="m-3 mr-4 mb-0 font-inter font-bold text-lg bg-right bg-no-repeat">
          {sequenceId}. {title}
        </h2>
      )}
      <img
        src={statusCar === "timer" ? carTimer : cover}
        height={200}
        className={cn(
          "grow aspect-[26/12] z-10 mx-auto mb-1 object-none",
          statusCar === "timer"
            ? "border w-full border-gray-light rounded-6 bg-yellow-primary"
            : "object-contain w-4/5 "
        )}
      />

      <div
        className={cn(
          "flex gap-1.5 z-10 pt-0 whitespace-pre-wrap font-medium text-xs leading-normal",
          statusCar !== "timer" && "p-1.5"
        )}
      >
        <Card className="grow p-2">
          <div className="text-gray-light">{t("carService.breaking")}</div>
          {breakdown.breakdown[`title_${language as Lang}`]}
        </Card>
        <Card className="p-2 w-32">
          {statusCar === "timer" && (
            <Button className={"w-full rounded-6 px-5 py-2"}>
              <Time
                repairedAt={breakdown.repaired_at!}
                updateStatusCar={updateStatusCar}
              />
            </Button>
          )}

          {statusCar !== "timer" && (
            <Button
              onClick={() =>
                setSelectedCar({
                  id: idItem,
                  status: statusCar,
                })
              }
              variant={statusCar === "completed" ? "green" : undefined}
              className={cn("w-full rounded-6 p-2", {
                "text-xs leading-6": language === "ua" && statusCar === "break",
              })}
            >
              {statusCar === "completed"
                ? t("carService.take")
                : t("carService.fix")}
            </Button>
          )}
        </Card>
      </div>
      {statusCar !== "timer" && (
        <div
          style={{
            backgroundImage: `url(${imgTire})`,
          }}
          className="absolute inset-0 top-1/2 bg-black-dark bg-no-repeat bg-contain"
        />
      )}
    </li>
  );
};
