import { Button } from "@/components/ui/Button";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ParkSidebar } from "./ParkSidebar";
import { GameContext } from "../GamePage";

export function Footer() {
  const { t } = useTranslation("common");

  // const [isParkOpen, setIsParkOpen] = useState(false);
  const { setIsParkOpen } = useContext(GameContext);

  return (
    <>
      <div className="fixed bottom-5 right-3 left-3">
        <Button
          className="w-full"
          onClick={() => {
            setIsParkOpen(true);
          }}
        >
          {t("game.footer.park")}
        </Button>
      </div>

      <ParkSidebar /*isParkOpen={isParkOpen} setIsParkOpen={setIsParkOpen}*/ />
    </>
  );
}
