import { apiMutations, apiQueries } from "@/api/queries";
import { Button } from "@/components/ui/Button";
import { Spinner } from "@/components/ui/Spinner";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { SuccessMutation } from "./SuccessMutationContext";
import { GameContext } from "../GamePage";
import { TXY } from "@/variables";
import { cn } from "@/utils";
import { usePlatform } from "@/hooks/usePlatform";

export function ActionsWithAll() {
  const { t } = useTranslation("common");
  const { isApplePlatform } = usePlatform();

  const { invalidateCars, invalidateProfile } = useContext(SuccessMutation);
  const { setCarIntervals } = useContext(GameContext);

  const { data: gameStatistic, refetch: refetchStatistic } = useQuery(
    apiQueries.getGameStatistics()
  );

  const { txy_to_collect, ready_to_ride_cars_count } = gameStatistic ?? {};

  const collectAll = useMutation({
    ...apiMutations.collectAllIncomeFromGars,
    onSuccess: () => {
      refetchStatistic();
      invalidateProfile();
      invalidateCars();
      setCarIntervals({
        type: "clean",
      });
    },
  });

  const sendAllForWork = useMutation({
    ...apiMutations.sendAllCarsForWork,
    onSuccess: () => {
      refetchStatistic();
      invalidateCars();
    },
  });

  const isCollectAllDisabled = txy_to_collect === "0.00";
  const isSendAllDisabled = ready_to_ride_cars_count === 0;

  return (
    <div
      className={cn(
        "flex gap-3 shadow-lg z-20 absolute bottom-0 left-0 right-0 bg-white-light px-3 py-4",
        { "pt-4 pb-6": isApplePlatform }
      )}
      style={{
        boxShadow: "0px -4px 10.1px 0px #E4E4E4",
      }}
    >
      <Button
        className={cn(
          "w-full px-1.5 py-3 text-sm flex items-center justify-center",
          {
            "text-white": isCollectAllDisabled,
          }
        )}
        variant="green"
        onClick={() => {
          collectAll.mutateAsync();
        }}
        disabled={isCollectAllDisabled}
      >
        {collectAll.isPending ? (
          <Spinner size="small" />
        ) : (
          `${t("game.footer.collect-all")} ${
            isCollectAllDisabled || !txy_to_collect
              ? ""
              : `${txy_to_collect} ${TXY}`
          }`
        )}
      </Button>
      <Button
        className="w-full px-1.5 py-3 text-sm text-white-light flex items-center justify-center"
        variant="dark"
        onClick={() => {
          sendAllForWork.mutateAsync();
        }}
        disabled={isSendAllDisabled}
      >
        {sendAllForWork.isPending ? (
          <Spinner size="small" />
        ) : (
          `${t("game.footer.send-to-work-all")} ${
            !ready_to_ride_cars_count || isSendAllDisabled
              ? ""
              : `(${ready_to_ride_cars_count})`
          }`
        )}
      </Button>
    </div>
  );
}
