import { UseMutationOptions, UseQueryOptions } from "@tanstack/react-query";
import { api, ApiError, ParamsGetMarketCars } from ".";

export enum QueryKey {
  gameCars = "gameCars",
  gameStatistics = "gameStatistics",
  userProfile = "userProfile",
  userParkCars = "userParkCars",
  marketParkCars = "marketParkCars",
  userTasks = "userTasks",
  parkCarInfo = "parkCarInfo",
  parkCarsCollection = "parkCarsCollection",
  marketCarInfo = "marketCarInfo",
  exchanges = "exchanges",
  currentRate = "currentRate",
  carsService = "carsService",
  referralInfo = "referralInfo",
  referralsList = "referralsList",
  transactions = "transactions",
  ratingsCars = "ratingsCars",
  ratingsEarnings = "ratingsEarnings",
  ratingsCarsMe = "ratingsCarsMe",
  ratingsEarningsMe = "ratingsEarningsMe",
}
export const apiQueries = {
  getUserParkCars: {
    queryKey: [QueryKey.userParkCars],
    queryFn: () =>
      api.getUserParkCars({
        size: 100,
        ordering: "car__discount_price",
      }),
  },

  getMarketCars(params?: ParamsGetMarketCars) {
    return {
      queryKey: [QueryKey.userParkCars, params],
      queryFn: () => api.getMarketCars(params),
    };
  },

  getUserTasks: {
    queryKey: [QueryKey.userTasks],
    queryFn: () => api.getUserTasks(),
  },

  getProfile: {
    queryKey: [QueryKey.userProfile],
    queryFn: () => api.getProfile(),
  },

  getParkCarInfo(params: Parameters<typeof api.getParkCarInfo>[0]) {
    return {
      queryKey: [QueryKey.parkCarInfo, params],
      queryFn: () => api.getParkCarInfo(params),
    };
  },

  getParkCarsCollection({
    id,
    params,
  }: {
    id: number;
    params: Parameters<typeof api.getParkCarsCollection>[1];
  }) {
    return {
      queryKey: [QueryKey.parkCarsCollection, id, params],
      queryFn: () => api.getParkCarsCollection(id, params),
    };
  },

  getMarketCarInfo(params: Parameters<typeof api.getMarketCarInfo>[0]) {
    return {
      queryKey: [QueryKey.marketCarInfo, params],
      queryFn: () => api.getMarketCarInfo(params),
    };
  },

  getGameCars(params?: Parameters<typeof api.getGameCars>[0]) {
    return {
      queryKey: [QueryKey.gameCars, params],
      queryFn: () => api.getGameCars(params),
    };
  },

  getGameCarsInfinity(params?: Parameters<typeof api.getGameCars>[0]) {
    return {
      queryKey: [QueryKey.gameCars, params],
      queryFn: ({ pageParam }: { pageParam?: unknown }) =>
        api.getGameCars({
          ...params,
          size: 14,
          page: (pageParam as number) || 1,
        }),
    };
  },

  getGameStatistics() {
    return {
      queryKey: [QueryKey.gameStatistics],
      queryFn: () => api.getGameStatistics(),
    };
  },

  getExchanges(params: Parameters<typeof api.getExchanges>[0]) {
    return {
      queryKey: [QueryKey.exchanges, params],
      queryFn: () => api.getExchanges(params),
    };
  },

  getCurrentRate: {
    queryKey: [QueryKey.currentRate],
    queryFn: () => api.getCurrentRate(),
  },

  getCarsServiceInfinity() {
    return {
      queryKey: [QueryKey.carsService],
      queryFn: ({ pageParam }: { pageParam?: unknown }) =>
        api.getCarsService({
          size: 10,
          page: (pageParam as number) || 1,
        }),
    };
  },

  getReferralInfo: {
    queryKey: [QueryKey.referralInfo],
    queryFn: () => api.getReferralInfo(),
  },
  getReferralsList(params: Parameters<typeof api.getReferralsList>[0]) {
    return {
      queryKey: [QueryKey.referralsList, params],
      queryFn: () => api.getReferralsList(params),
    };
  },
  getAvatars: {
    queryKey: ["avatars"],
    queryFn: () => api.getAvatars(),
  },
  getReferralsStatuses: {
    queryKey: ["referralsStatuses"],
    queryFn: () => api.getReferralsStatuses(),
  },
  getTransactions(params: Parameters<typeof api.getTransactions>[0]) {
    return {
      queryKey: [QueryKey.transactions, params],
      queryFn: () => api.getTransactions(params),
    };
  },

  getRatingCars(/*params: Parameters<typeof api.getRatingCars>[0]*/) {
    return {
      queryKey: [QueryKey.ratingsCars /*params*/],
      queryFn: () => api.getRatingCars(/*params*/),
    };
  },

  getRatingCarsMe() {
    return {
      queryKey: [QueryKey.ratingsCarsMe],
      queryFn: () => api.getRatingCarsMe(),
    };
  },

  getRatingEarnings(/*params: Parameters<typeof api.getRatingEarnings>[0]*/) {
    return {
      queryKey: [QueryKey.ratingsEarnings /*params*/],
      queryFn: () => api.getRatingEarnings(/*params*/),
    };
  },

  getRatingEarningsMe() {
    return {
      queryKey: [QueryKey.ratingsEarningsMe],
      queryFn: () => api.getRatingEarningsMe(),
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} satisfies Record<string, UseQueryOptions | ((args: any) => UseQueryOptions)>;

export const apiMutations = {
  collectIncomeFromGar: {
    mutationFn: (id: number) => api.collectIncomeFromGar(id),
  },
  collectAllIncomeFromGars: {
    mutationFn: () => api.collectAllIncomeFromGars(),
  },

  sendCarForWork: {
    mutationFn: (id: number) => api.sendCarForWork(id),
  },

  sendAllCarsForWork: {
    mutationFn: () => api.sendAllCarsForWork(),
  },

  transferToBalance: {
    mutationFn: () => api.transferToBalance(),
  },

  requestWithdrawal: {
    mutationFn: (data) => api.requestWithdrawal(data),
  },
  convertTXYForRequest: {
    mutationFn: (data) => api.convertTXYForRequest(data),
  },

  addSticker: {
    mutationFn: (id: number) => api.addSticker(id),
  },

  purchaseCar: {
    mutationFn: (id: number) => api.purchaseCar(id),
  },

  changeAvatar: {
    mutationFn: (id: string | null) => api.changeAvatar(id),
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} satisfies Record<string, UseMutationOptions<any, ApiError, any>>;

