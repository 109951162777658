import imgBgTiers from "@/assets/exclamation.webp";
import imgExclamationMark from "@/assets/tiers-sidebar.webp";
import { Button } from "@/components/ui/Button";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "@/components/ui/SnackBar/SnackbarContext";

export { imgBgTiers, imgExclamationMark };

const ConnectionFailure = () => {
  const { t } = useTranslation("common");
  const { setShowWithProps } = useSnackbar();

  const closeSnackbar = () => {
    setShowWithProps(null);
  };

  const onMainBtnClick = () => {
    window.location.reload();
  };

  const onCancelBtnClick = () => {
    closeSnackbar();
  };

  return (
    <div className={"text-center"}>
      <div
        style={{
          backgroundImage: `url(${imgExclamationMark})`,
          backgroundSize: "cover",
        }}
        className={"w-full h-44 bg-no-repeat flex items-center justify-center"}
      >
        <div
          style={{
            backgroundImage: `url(${imgBgTiers})`,
            backgroundSize: "100%",
          }}
          className={
            "size-24 bg-no-repeat bg-contain bg-center bg-yellow-primary rounded-full"
          }
        />
      </div>
      <div className="px-4">
        <h3 className={"font-montserrat text-lg font-black italic mb-1"}>
          {t("errorSnackbars.connectionFailure.title")}
        </h3>
        <p className={"font-inter text-sm"}>
          {t("errorSnackbars.connectionFailure.description")}
        </p>

        <div className={"flex gap-3 mt-9 mb-6"}>
          <Button className={"w-full"} onClick={onCancelBtnClick}>
            {t("errorSnackbars.cancel")}
          </Button>
          <Button className={"w-full"} onClick={onMainBtnClick} variant="dark">
            {t("errorSnackbars.connectionFailure.mainBtn")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConnectionFailure;
