import { apiMutations, apiQueries } from "@/api/queries";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useQueryAvatars = ({closeSideBar}: {closeSideBar: () => void}) => {
    const { data: avatars } = useQuery(apiQueries.getAvatars);

    const queryClient = useQueryClient();

    const onSuccess = () => {
        queryClient.invalidateQueries(apiQueries.getProfile);
        closeSideBar();
    }

    const mutationAvatar = useMutation({...apiMutations.changeAvatar, onSuccess });

    const changeAvatar = (id: string | null) => {
        return mutationAvatar.mutate(id);
    };

    return {avatars, changeAvatar};
};