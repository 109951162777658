import { ResponseGetGameCars } from "@/api";
import { ParkCard } from "./ParkCard";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

type UserCarsProps = {
  userCars: ResponseGetGameCars | undefined;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isFetching: boolean;
};
export const UserCars = ({
  userCars,
  fetchNextPage,
  hasNextPage,
  isFetching,
}: UserCarsProps) => {
  const { ref, inView } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (inView && hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [hasNextPage, inView]);

  return (
    <ul className="grid grid-cols-2 gap-2 max-h-[450px] md:max-h-[600px] overflow-y-auto pb-20">
      {userCars?.results.map((car) => (
        <ParkCard key={car.id} car={car} />
      ))}
      <li ref={ref} />
    </ul>
  );
};
