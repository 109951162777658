import { SideBar } from "@/components/sideBar/SideBar";
import { usePlatform } from "@/hooks/usePlatform";
import { cn } from "@/utils";
import { Snackbar, SnackbarProps } from "@telegram-apps/telegram-ui";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { SuccessIcon } from "./SuccessIcon";
import { ErrorIcon } from "./ErrorIcon";

type SnackbarAdditionalProps = {
  type?: "text" | "sidebar";
  isSuccess?: boolean;
  isError?: boolean;
  duration?: number;
  icon?: React.ReactNode;
};
export type PartialSnackbarProps = Partial<SnackbarProps> &
  SnackbarAdditionalProps;
type SnackbarCtx = {
  setShowWithProps: Dispatch<SetStateAction<PartialSnackbarProps | null>>;
  isOpened: boolean;
};

const SnackbarContext = createContext<SnackbarCtx>({} as SnackbarCtx);

export function SnackbarProvider(p: { children: React.ReactNode }) {
  const [showWithProps, setShowWithProps] =
    useState<PartialSnackbarProps | null>(null);
  const { isApplePlatform } = usePlatform();
  const { onClose } = showWithProps || {};

  const isSnackbarOpen = Boolean(showWithProps);

  const onSnackbarClose = () => {
    setShowWithProps(null);
    onClose?.();
  };

  const snackbarContent = useMemo(
    () =>
      getSnackBarContent({
        isSnackbarOpen,
        showWithProps: showWithProps || {},
        isApplePlatform,
        onClose: onSnackbarClose,
      }),
    [isSnackbarOpen]
  );

  return (
    <SnackbarContext.Provider
      value={{ setShowWithProps, isOpened: Boolean(showWithProps) }}
    >
      {snackbarContent}
      {p.children}
    </SnackbarContext.Provider>
  );
}

type GetSnackBarContentOptions = {
  isSnackbarOpen: boolean;
  isApplePlatform: boolean;
  showWithProps: PartialSnackbarProps;
  onClose: () => void;
};

const getSnackBarContent = (options: GetSnackBarContentOptions) => {
  const { isSnackbarOpen, onClose, showWithProps, isApplePlatform } = options;

  const {
    type = "text",
    className,
    isSuccess,
    isError,
    duration,
    icon,
    ...restProps
  } = showWithProps;

  if (type === "sidebar")
    return (
      <SideBar
        withoutPaddings
        isOpen={isSnackbarOpen}
        setIsOpen={(isOpen: boolean) => {
          !isOpen && onClose();
        }}
        withCross={false}
      >
        {restProps.children}
      </SideBar>
    );

  return (
    isSnackbarOpen && (
      <Snackbar
        duration={duration ?? 3000}
        style={{
          // @ts-expect-error no vars types
          "--tgui--surface_dark": "#111112",
          "--tgui--white": "#FEFEFE",
          animation: "none",
          transition: "none",
        }}
        className={cn("z-50 mb-2 top-2 right-3 left-3", {
          "mb-4": isApplePlatform,
          className,
        })}
        onClose={onClose}
        before={
          icon ? (
            icon
          ) : isSuccess ? (
            <SuccessIcon />
          ) : isError ? (
            <ErrorIcon />
          ) : undefined
        }
        {...restProps}
      />
    )
  );
};

export function useSnackbar() {
  return useContext(SnackbarContext);
}
