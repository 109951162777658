import { apiQueries } from "@/api/queries";

import { CarInfo } from "@/components/car/CarInfo";
import { useAppParams } from "@/navigation/useAppParams";
import { useQuery } from "@tanstack/react-query";
import { HeaderScreen } from "../../profile/HeaderScreen";
import { CarsCollection } from "./CarsCollection";
import carImg from "../../market/competition-Mersedes-E-klass.webp";
import competitionInfo from "../../market/competition-info.webp";

import { useEffect } from "react";
import { useSnackbar } from "@/components/ui/SnackBar/SnackbarContext";
import { useTranslation } from "react-i18next";
import { COMPETITION_CAR_NAME } from "@/variables";
import { isTodayInCompetitionRange } from "@/utils";

export function ParkCarInfoPage() {
  const { carId } = useAppParams();
  const { data: carInfo, isSuccess } = useQuery(
    apiQueries.getParkCarInfo(+carId!)
  );
  const { t } = useTranslation("common");

  const { setShowWithProps } = useSnackbar();

  const isCompetitionCar =
    carInfo?.title === COMPETITION_CAR_NAME && isTodayInCompetitionRange();

  useEffect(() => {
    if (isCompetitionCar) {
      setShowWithProps({
        children: t("competition.carSnackbarText"),
        icon: <img src={competitionInfo} alt="gift" className="w-7 h-7" />,
      });
    }
  }, [isCompetitionCar]);

  if (!isSuccess) {
    return null;
  }

  return (
    <div>
      <HeaderScreen title={carInfo.title} isBack />
      <CarInfo
        // cover={carInfo.cover.file}
        cover={isCompetitionCar ? carImg : carInfo.cover.file}
        profits={{
          perDay: {
            txy: carInfo.daily_txy_profit,
            usdt: carInfo.daily_usdt_profit,
          },
          perMonth: {
            txy: carInfo.monthly_txy_profit,
            usdt: carInfo.monthly_usdt_profit,
          },
          perYear: {
            txy: carInfo.annual_txy_profit,
            usdt: carInfo.annual_usdt_profit,
          },
        }}
        descriptionRu={carInfo.description_ru}
        descriptionUa={carInfo.description_ua}
        descriptionEn={carInfo.description_en}
        reliability={carInfo.reliability}
        isCompetitionCar={isCompetitionCar}
      />

      {/* not a free car - only 1 car for user */}
      {carId &&
        ((carInfo?.max_count_per_user && carInfo?.max_count_per_user > 1) ||
          carInfo.max_count_per_user === null) && (
          <CarsCollection carInfo={carInfo} />
        )}
    </div>
  );
}
