import {
  imgConditionsOfUse,
  imgPrivacyPolicy,
  imgReferralProgram,
  imgTasks,
  imgWallet,
  imgTransactions,
  imgRating,
  imgFunds,
  imgInstruction,
} from "@/assets/profile";
import { ROUTE } from "@/config/navigation";
import { Lang, TranslateNamespaces } from "@/localization/localizationConfig";
import { INSTRUCTIONS, PRIVACY_POLICY, TERMS_OF_USE } from "@/variables";

type NavItemProfileStatic = {
  img: string;
  title: keyof TranslateNamespaces["common"]["profile"]["links"];
  forBeta: boolean;
};
type NavItemProfile =
  | (NavItemProfileStatic & {
      path: (typeof ROUTE)[keyof typeof ROUTE];
      link?: never;
    })
  | (NavItemProfileStatic & {
      link: string;
      path?: never;
    });

export const getNavItemsProfile = (lang: Lang): NavItemProfile[] => [
  {
    title: "wallet",
    path: ROUTE.WALLET,
    img: imgWallet,
    forBeta: true,
  },
  {
    title: "balance",
    path: ROUTE.BALANCE,
    img: imgFunds,
    forBeta: false,
  },
  {
    title: "tasks",
    path: ROUTE.TASKS,
    img: imgTasks,
    forBeta: false,
  },
  {
    title: "transactions",
    path: ROUTE.TRANSACTIONS,
    img: imgTransactions,
    forBeta: false,
  },
  {
    title: "rating",
    path: ROUTE.RATING,
    img: imgRating,
    forBeta: false,
  },
  {
    title: "refs",
    path: ROUTE.REFS,
    img: imgReferralProgram,
    forBeta: false,
  },
  {
    title: "instruction",
    img: imgInstruction,
    link: INSTRUCTIONS[lang] ?? INSTRUCTIONS.en,
    forBeta: false,
  },
  {
    title: "politic",
    img: imgPrivacyPolicy,
    link: PRIVACY_POLICY,
    forBeta: false,
  },
  {
    title: "terms",
    img: imgConditionsOfUse,
    link: TERMS_OF_USE,
    forBeta: false,
  },
];
