import { api } from "@/api";
import { invalidateByPredicate } from "@/api/apiUtils";
import { apiQueries, QueryKey } from "@/api/queries";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

export const useQueryCarService = ({
  closeSideBar,
}: {
  closeSideBar: () => void;
}) => {
  const serviceInfinityCars = useInfiniteQuery({
    ...apiQueries.getCarsServiceInfinity(),
    initialPageParam: 1,
    getNextPageParam: (lastPageData, _, lastPageParam: number) => {
      return lastPageData.next ? lastPageParam + 1 : undefined;
    },
    refetchInterval: 1000 * 60 * 3,
  });

  const invalidateCarsService = () =>
    queryClient.invalidateQueries({
      predicate: invalidateByPredicate([QueryKey.carsService]),
    });
  const onSuccessRepair = () => {
    invalidateCarsService();
    queryClient.invalidateQueries(apiQueries.getProfile);
    closeSideBar();
  };

  const onSuccessCollect = () => {
    invalidateCarsService();
  };

  const queryClient = useQueryClient();

  const mutationRepair = useMutation({
    mutationFn: api.repairCar,
    onSuccess: onSuccessRepair,
  });
  const mutationCollect = useMutation({
    mutationFn: api.collectCar,
    onSuccess: onSuccessCollect,
  });

  const repair = async (id: number) => {
    return mutationRepair.mutate(id);
  };

  const collect = async (id: number) => {
    mutationCollect.mutate(id);
  };

  return { repair, collect, serviceInfinityCars };
};
