import { imgCoinTxy66 } from "@/assets";
import { cn } from "@/utils";

export function CoinIcon({ size = "large" }: { size?: "small" | "large" }) {
  return (
    <div
      style={{
        backgroundImage: `url(${imgCoinTxy66})`,
      }}
      className={cn(
        "bg-no-repeat bg-cover bg-center rounded-full",
        size === "small" ? "size-4" : "size-6"
      )}
    />
  );
}
