import imgHead from "@/assets/exclamation.webp";
import background from "@/assets/tiers-sidebar.webp";
import { Button } from "@/components/ui/Button";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "@/components/ui/SnackBar/SnackbarContext";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "@/config/navigation";

const InsufficientFunds = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const { setShowWithProps } = useSnackbar();

  const closeSnackbar = () => {
    setShowWithProps(null);
  };

  const onMainBtnClick = () => {
    navigate(ROUTE.BALANCE);
    closeSnackbar();
  };

  const onCancelBtnClick = () => {
    closeSnackbar();
  };

  return (
    <div className={"text-center"}>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
        }}
        className={"w-full h-44 bg-no-repeat flex items-center justify-center"}
      >
        <div
          style={{ backgroundImage: `url(${imgHead})`, backgroundSize: "100%" }}
          className={
            "size-24 bg-no-repeat bg-contain bg-center bg-yellow-primary rounded-full"
          }
        />
      </div>
      <div className="px-4">
        <h3 className={"font-montserrat text-lg font-black italic mb-1"}>
          {t("errorSnackbars.insufficientFunds.title")}
        </h3>
        <p className={"font-inter text-sm"}>
          {t("errorSnackbars.insufficientFunds.description")}
        </p>

        <div className={"flex gap-3 mt-9 mb-6"}>
          <Button className={"w-full"} onClick={onCancelBtnClick}>
            {t("errorSnackbars.cancel")}
          </Button>
          <Button className={"w-full"} onClick={onMainBtnClick} variant="dark">
            {t("errorSnackbars.insufficientFunds.mainBtn")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InsufficientFunds;
