import { Head } from "@/components/ui/Head";
import { ROUTE } from "@/config/navigation";
import competitionInfoImage from "./competition-info.webp";
import { Button } from "@/components/ui/Button";
import { useTranslation } from "react-i18next";

export const CompetitionSidebar = () =>
  // p: { competitionCarId: number | null }
  {
    const { t } = useTranslation("common");

    return (
      <div className="text-center">
        <img
          alt="competition image"
          src={competitionInfoImage}
          className="w-36 mx-auto mb-3"
        />

        <Head className="mb-2">{t("competition.title")}</Head>
        <p className="text-gray-dark px-4 text-sm mb-4">
          {t("competition.text")}
        </p>
        {/* <p className="text-gray-dark px-4 text-sm">{t("competition.text2")}</p> */}

        <Button
          onClick={() => {
            window.location.hash = ROUTE.BALANCE;
          }}
          className="w-full flex justify-center mt-6"
          variant="dark"
        >
          {t("competition.button")}
        </Button>
      </div>
    );
  };
