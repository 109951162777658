import { useTranslation } from "react-i18next";
import noDataIcon from "@/assets/car-service.png";
import { StatusCar } from "./ItemCarService";
import { SideBar } from "@/components/sideBar/SideBar";
import { useEffect, useState } from "react";
import { SideBarContent } from "./SideBarContent";
import { useQueryCarService } from "./useQueryCarService";
import { CarsList } from "./CarsList";

export type SelectedCar = {
  id: number | null;
  status: StatusCar;
};

export const CarService = () => {
  const { t } = useTranslation();

  const [selectedCar, setSelectedCar] = useState<SelectedCar | null>(null);
  const [isOpenSideBar, setIsOpenSideBar] = useState(false);

  useEffect(() => {
    if (selectedCar) {
      setIsOpenSideBar(true);
    }
  }, [selectedCar]);

  const closeSideBar = () => {
    setIsOpenSideBar(false);
    // не очищаем setSelectedCar при закрытии, будем его только пререзаписывать т.к. могут возникать баги при очистке по таймеру, а без таймера - контент меняется до закрытия сайдбара
  };

  const { serviceInfinityCars, repair, collect } = useQueryCarService({
    closeSideBar,
  });

  const { data, fetchNextPage, hasNextPage, isFetching } = serviceInfinityCars;

  const serviceCars = {
    results: data?.pages.flatMap((page) => page.results) ?? [],
    count: data?.pages[0]?.count ?? 0,
    next: data?.pages[data?.pages.length - 1]?.next ?? null,
    previous: data?.pages[data?.pages.length - 1]?.previous ?? null,
  };

  const selectCarItem = serviceCars?.results.find(
    (item) => item.id === selectedCar?.id
  )?.last_breakdown;

  const onSubmit = async () => {
    if (!selectedCar) {
      return;
    }
    if (selectedCar.status === "break") {
      await repair(selectedCar.id!);
    }
    if (selectedCar.status === "completed") {
      await collect(selectedCar.id!);
    }
    return closeSideBar();
  };

  if (!serviceCars || serviceCars.results.length === 0) {
    return (
      <div className={"my-auto"}>
        <div
          style={{ backgroundImage: `url(${noDataIcon})` }}
          className={
            "size-24 bg-no-repeat bg-contain bg-center self-center mb-5 mx-auto"
          }
        ></div>
        <p className={"text-gray-middle text-center"}>
          {t("carService.empty")}
        </p>
      </div>
    );
  }

  return (
    <>
      <CarsList
        serviceCars={serviceCars}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetching={isFetching}
        setSelectedCar={setSelectedCar}
      />

      <SideBar
        withoutPaddings
        isOpen={isOpenSideBar}
        setIsOpen={closeSideBar}
        withCross={selectedCar?.status !== "completed"}
      >
        <SideBarContent
          onSubmit={onSubmit}
          statusCar={selectedCar?.status}
          breakdown={selectCarItem}
        />
      </SideBar>
    </>
  );
};
