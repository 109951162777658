import { useTranslation } from "react-i18next";
import { HeaderScreen } from "../HeaderScreen";
import { BalanceField } from "@/components/BalanceField";
import { Tabs } from "@/components/ui/Tabs";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { apiQueries } from "@/api/queries";
import { Spinner } from "@/components/ui/Spinner";
import { List } from "./List";

export function RatingPage() {
  const { t } = useTranslation("common");

  const [status, setStatus] = useState<"earnings" | "cars">("earnings");

  const tabs = [
    { label: t("rating.earnings"), id: "earnings" },
    { label: t("rating.cars"), id: "cars" },
  ];

  const { data: me } = useQuery({
    ...apiQueries.getProfile,
    refetchOnMount: false,
  });

  const { data: carsRatingData, isPending: isCarsPending } = useQuery(
    apiQueries.getRatingCars()
  );

  const { data: earningRatingData, isPending: isEarningPending } = useQuery(
    apiQueries.getRatingEarnings()
  );

  const isMyProfileInCarsRatings = Boolean(
    carsRatingData?.results.find(({ id }) => id === me?.id)
  );

  const isMyProfileInEarningsRatings = Boolean(
    earningRatingData?.results.find(({ id }) => id === me?.id)
  );

  const { data: myCarsRatingData, isPending: isMyCarsRatingPending } = useQuery(
    {
      ...apiQueries.getRatingCarsMe(),
      // enabled: carsRatingData ? !isMyProfileInCarsRatings : false,
    }
  );

  const { data: myEarningsRatingData, isPending: isMyEarningsRatingPending } =
    useQuery({
      ...apiQueries.getRatingEarningsMe(),
      // enabled: earningRatingData ? !isMyProfileInEarningsRatings : false,
    });

  return (
    <div className="mb-8">
      <HeaderScreen isBack title={t("rating.title")}>
        <BalanceField />
      </HeaderScreen>

      <div>
        <Tabs
          tabs={tabs}
          activeTab={status}
          onChange={(tab) => {
            if (tab === "earnings" || tab === "cars") {
              setStatus(tab);
            }
          }}
        />

        <p className="text-gray-middle text-sm mb-2.5 mt-2">
          {t(`rating.subtitle.${status}`)}
        </p>

        {(isMyCarsRatingPending ||
          isMyEarningsRatingPending ||
          isCarsPending ||
          isEarningPending) && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Spinner />
          </div>
        )}

        {!isCarsPending && status === "cars" && (
          <List
            currentUserId={me?.id}
            data={carsRatingData?.results}
            myRankData={myCarsRatingData}
            isMeInTopRating={isMyProfileInCarsRatings}
            isCarsStatus={true}
          />
        )}

        {!isEarningPending && status === "earnings" && (
          <List
            currentUserId={me?.id}
            data={earningRatingData?.results}
            myRankData={myEarningsRatingData}
            isMeInTopRating={isMyProfileInEarningsRatings}
            isCarsStatus={false}
          />
        )}
      </div>
    </div>
  );
}
