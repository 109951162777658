import { CardProfile } from "@/components/cards/CardProfile/CardProfile";
import { Link } from "@/components/Link/Link";
import { getNavItemsProfile } from "./config";
import { ProfileInfo } from "./ProfileInfo";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { HeaderScreen } from "./HeaderScreen";
import {
  Lang,
  LANGS,
  TranslateNamespaces,
} from "@/localization/localizationConfig";
import { cn } from "@/utils";
import { useQuery } from "@tanstack/react-query";
import { apiQueries } from "@/api/queries";

const MenuItem = ({
  title,
  img,
  onClick,
}: {
  title: keyof TranslateNamespaces["common"]["profile"]["links"];
  img: string;
  onClick?: () => void;
}) => {
  const { t } = useTranslation("common");

  return (
    <CardProfile
      className={"p-4 flex gap-2 my-2 items-center justify-between pe-5"}
      onClick={onClick}
    >
      <div className={"flex gap-2 font-bold font-inter"}>
        <div
          style={{
            backgroundImage: `url(${img})`,
          }}
          className={
            "bg-no-repeat bg-contain bg-center bg-white-20 h-8 w-8 rounded-full"
          }
        />

        <h4 className={"text-white-light my-1.5 font-inter text-sm"}>
          {t(`profile.links.${title}`)}
        </h4>
      </div>

      <svg
        width="8"
        height="13"
        viewBox="0 0 8 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.6254 6.49994C7.6183 6.25137 7.52597 6.03832 7.33423 5.84657L1.80906 0.442143C1.64572 0.285905 1.45398 0.200684 1.21962 0.200684C0.743803 0.200684 0.374512 0.569975 0.374512 1.04579C0.374512 1.27305 0.466834 1.4861 0.630175 1.64944L5.6014 6.49994L0.630175 11.3504C0.466834 11.5138 0.374512 11.7197 0.374512 11.9541C0.374512 12.4299 0.743803 12.7992 1.21962 12.7992C1.44688 12.7992 1.64572 12.714 1.80906 12.5577L7.33423 7.14619C7.53307 6.96155 7.6254 6.7485 7.6254 6.49994Z"
          fill="#FEFEFE"
        />
      </svg>
    </CardProfile>
  );
};

const langs = Object.values(LANGS);
const langClassnames =
  "flex items-center justify-center bg-gray-10 rounded-full size-9 font-inter font-bold text-yellow-primary leading-none text-sm uppercase";
const LangChange = () => {
  const {
    i18n: { changeLanguage, resolvedLanguage },
  } = useTranslation("common");

  const [isOpenLangs, setIsOpenLangs] = useState(false);

  const toggleLangs = () => {
    setIsOpenLangs(!isOpenLangs);
  };

  const changeLang = (lang: Lang) => {
    changeLanguage(lang);
    toggleLangs();
  };

  const availableLangs = langs.filter((lang) => lang !== resolvedLanguage);

  return (
    <div className={"flex gap-2 font-inter font-bold"}>
      {isOpenLangs &&
        availableLangs.map((lang) => (
          <button
            key={lang}
            onClick={() => changeLang(lang)}
            className={langClassnames}
          >
            <h4>{lang}</h4>
          </button>
        ))}

      <button
        onClick={toggleLangs}
        className={cn(langClassnames, {
          "bg-yellow-primary text-black-dark": isOpenLangs,
        })}
      >
        <h4>{resolvedLanguage}</h4>
      </button>
    </div>
  );
};

export const ProfilePage = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const title = t("profile.title");

  const { data } = useQuery(apiQueries.getProfile);

  return (
    <div className={"mb-1"}>
      <HeaderScreen isBack title={title}>
        <LangChange />
      </HeaderScreen>
      <ProfileInfo data={data} />
      <ul className="pb-6">
        {getNavItemsProfile(language as Lang).map((item, index) => {
          return (
            <li key={index} className="font-inter font-bold">
              {item.link ? (
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-inter font-bold"
                >
                  <MenuItem title={item.title} img={item.img} />
                </a>
              ) : (
                <Link to={item.path!} className="font-inter font-bold">
                  <MenuItem title={item.title} img={item.img} />
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
