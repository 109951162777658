import { TXY } from "@/variables";
import { useTranslation } from "react-i18next";
import { cn } from "@/utils";
import { PaymentType, ResponseGetTransactions, TransactionStatus } from "@/api";

type TranslationsStatus =
  | "successfully"
  | "approved"
  | "inProgress"
  | "rejected"
  | "pending";

const getStatusKeys = (
  type: PaymentType
): Record<TransactionStatus, TranslationsStatus> => ({
  successful: type === "deposit" ? "successfully" : "approved",
  waiting: "inProgress",
  failed: "rejected",
  under_consideration: "pending",
  rejected: "rejected",
  processing: "inProgress",
});

export function ListItem(p: {
  data: ResponseGetTransactions;
  type: PaymentType;
}) {
  const { t } = useTranslation("common");

  const {
    pay_currency,
    amount_in_pay_currency,
    amount_in_game_currency,
    comment,
    status,
  } = p.data;

  const statusKey = getStatusKeys(p.type)[status];

  return (
    <li className=" bg-black-dark-card/70 px-3 py-3 rounded-10">
      <div className="flex gap-2 justify-between">
        <span className="font-bold w-full block">
          {Number(amount_in_game_currency)} {TXY}
        </span>
        <span
          className={cn("text-sm text-nowrap", {
            "text-green-completed":
              statusKey === "approved" || statusKey === "successfully",
            "text-yellow-primary":
              statusKey === "inProgress" || statusKey === "pending",
            "text-red": statusKey === "rejected",
          })}
        >
          {t(`transactions.status.${statusKey as TranslationsStatus}`)}
        </span>
      </div>
      <div className="text-sm mt-0.5 text-gray-middle">
        {comment ? comment.text : `${amount_in_pay_currency} ${pay_currency}`}
      </div>
    </li>
  );
}
