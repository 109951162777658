import { apiMutations, apiQueries } from "@/api/queries";
import { SideBar } from "@/components/sideBar/SideBar";
import { Button } from "@/components/ui/Button";
import { Head } from "@/components/ui/Head";
import { getMinTransferInTXY } from "@/utils";
import { TXY } from "@/variables";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useContext } from "react";
import { useTranslation } from "react-i18next";
import { SuccessMutation } from "./SuccessMutationContext";

export function TransferTOBalance({
  isTransferOpen,
  setIsTransferOpen,
  earnings,
}: {
  isTransferOpen: boolean;
  setIsTransferOpen: Dispatch<SetStateAction<boolean>>;
  earnings: number;
}) {
  const { t } = useTranslation("common");

  const { onSuccessTransfer } = useContext(SuccessMutation);

  const { data: currentRate } = useQuery({
    ...apiQueries.getCurrentRate,
  });

  const MIN_IN_TXY = getMinTransferInTXY(Number(currentRate?.usdt_per_txy));

  const transferToBalance = useMutation({
    ...apiMutations.transferToBalance,
    onSuccess: onSuccessTransfer,
  });

  const onTransferToBalance = () => {
    transferToBalance.mutateAsync();
  };

  return (
    <SideBar
      isOpen={isTransferOpen}
      setIsOpen={setIsTransferOpen}
      withCross={false}
    >
      <Head as={"h2"} className={"text-center mt-5 mb-5"}>
        {t("game.footer.transfer-income-title")}
      </Head>
      <p className="text-center text-sm">
        {t("game.footer.transfer-income-body", {
          minAmount: MIN_IN_TXY,
          currency: TXY,
        })}
      </p>

      <div className="flex gap-3 mt-9 mb-6">
        <Button
          onClick={() => {
            setIsTransferOpen(false);
          }}
          className="w-full"
        >
          {t("game.footer.cancel")}
        </Button>
        <Button
          disabled={Number(earnings) === 0}
          onClick={onTransferToBalance}
          variant="dark"
          className="w-full"
        >
          {t("game.footer.transfer")}
        </Button>
      </div>
    </SideBar>
  );
}
