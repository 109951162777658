import { appFetch } from "./appFetch";

export const api = {
  getUserParkCars(
    params?: PaginatorParams &
      FindParam &
      OrderingParam<
        | "id"
        | "car__discount_price"
        | "car__daily_txy_profit"
        | "car__reliability"
      >
  ) {
    return appFetch<ResponseGetUserCars>("users/fleet", undefined, {
      queryParams: params,
    });
  },

  getMarketCars(params?: ParamsGetMarketCars) {
    return appFetch<ResponseGetMarketCars>("users/markets/cars", undefined, {
      queryParams: params,
    });
  },

  getParkCarInfo(id: number) {
    return appFetch<ResponseGetParkCarInfo>(`users/fleet/${id}`);
  },

  getParkCarsCollection(
    id: number,
    params: PaginatorParams & CarsCollectionParam
  ) {
    return appFetch<WithPaginator<ResponseGetParkCarsCollection>>(
      `users/fleet/${id}/cars`,
      undefined,
      {
        queryParams: params,
      }
    );
  },

  getMarketCarInfo(id: number) {
    return appFetch<ResponseGetMarketCarInfo>(`users/markets/cars/${id}`);
  },

  getUserTasks() {
    return appFetch<ResponseGetUserTasks>("users/tasks");
  },

  getProfile() {
    return appFetch<ResponseGetProfile>("users/me");
  },

  getCarsService(params: PaginatorParams) {
    return appFetch<ResponseGetCarsService>(
      "users/repair-shops/cars",
      undefined,
      {
        queryParams: params,
      }
    );
  },
  repairCar(id: number) {
    return appFetch<CarServiceItem>(`users/repair-shops/cars/${id}/repair`, {
      method: "POST",
    });
  },
  collectCar(id: number) {
    return appFetch<CarServiceItem>(`users/repair-shops/cars/${id}/collect`, {
      method: "POST",
    });
  },
  getGameCars(params?: PaginatorParams & FindParam) {
    return appFetch<ResponseGetGameCars>("users/games/cars", undefined, {
      queryParams: params,
    });
  },

  collectIncomeFromGar(id: number) {
    return appFetch<ResponseGetGameCars>(`users/games/cars/${id}/collect`, {
      method: "POST",
    });
  },

  collectAllIncomeFromGars() {
    return appFetch(`users/games/cars/collect/all`, {
      method: "POST",
    });
  },

  sendCarForWork(id: number) {
    return appFetch<ResponseGetGameCars>(`users/games/cars/${id}/send`, {
      method: "POST",
    });
  },

  sendAllCarsForWork() {
    return appFetch(`users/games/cars/send/all`, {
      method: "POST",
    });
  },

  transferToBalance() {
    return appFetch<ResponseGetGameCars>(`users/games/cars/earnings/transfer`, {
      method: "POST",
    });
  },

  getGameStatistics() {
    return appFetch<ResponseGetGameStatistics>("users/games/statistics");
  },

  getExchanges(params: PaginatorParams & GetExchangesSpecialParams) {
    return appFetch<ResponseGetExchanges>("users/exchanges", undefined, {
      queryParams: params,
    });
  },
  getCurrentRate() {
    return appFetch<ResponseGetCurrentRate>("users/exchanges/current");
  },
  requestWithdrawal(data: RequestWithdrawal) {
    return appFetch(`users/transactions/withdrawal`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
  },
  convertTXYForRequest(data: RequestConvertTXY) {
    return appFetch<ResponseConvertTXY>(`users/requests/convert`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
  },

  getTransactions(params: PaginatorParams & GetTransactionsSpecialParams) {
    return appFetch<WithPaginator<ResponseGetTransactions>>(
      `users/transactions`,
      undefined,
      {
        queryParams: params,
      }
    );
  },

  // deprecated
  addSticker(id: number) {
    return appFetch<undefined>(`users/fleets/cars/${id}/sticker`, {
      method: "POST",
    });
  },

  purchaseCar(id: number) {
    return appFetch<undefined>(`users/markets/cars/${id}/purchase`, {
      method: "POST",
    });
  },

  getReferralsStatuses() {
    return appFetch<ReferralsStatus[]>("users/referral-program/statuses");
  },

  getReferralInfo() {
    return appFetch<ReferralInfo>("users/referral-program");
  },
  getReferralsList(params: PaginatorParams) {
    return appFetch<WithPaginator<ReferralNetworkItem>>(
      "users/referral-program/referrals",
      undefined,
      {
        queryParams: params,
      }
    );
  },
  getAvatars() {
    return appFetch<WithPaginator<Image>>("users/avatars", undefined, {
      queryParams: {
        page: 1,
        size: 20,
      },
    });
  },
  changeAvatar(id: string | null) {
    return appFetch<undefined>(`users/me`, {
      method: "PATCH",
      body: JSON.stringify({ avatar: id }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
  },

  getRatingCars() {
    return appFetch<WithPaginator<ResponseGetRatingCars>>(
      "users/ratings/cars",
      undefined,
      {
        queryParams: {
          page: 1,
          size: 15,
        },
      }
    );
  },

  getRatingEarnings() {
    return appFetch<WithPaginator<ResponseGetRatingEarnings>>(
      "users/ratings/earnings",
      undefined,
      {
        queryParams: {
          page: 1,
          size: 15,
        },
      }
    );
  },

  getRatingEarningsMe() {
    return appFetch<ResponseGetRatingEarningsMe>("users/ratings/me/earnings");
  },

  getRatingCarsMe() {
    return appFetch<ResponseGetRatingCarsMe>("users/ratings/me/cars");
  },

  convertTXYForDeposit(data: RequestConvertTXY) {
    return appFetch<ResponseConvertTXY>(`users/transactions/convert`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
  },

  createTransaction(data: RequestCreateTransaction) {
    return appFetch<ResponseCreateTransaction>(`users/transactions/deposit`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
  },
};

export type ApiError = {
  type: string;
  errors: {
    code: string;
    detail: string;
    attr: null;
  }[];
};

export type WithPaginator<T> = {
  results: T[] | [];
  count: number;
  next: string | null;
  previous: string | null;
};

type PaginatorParams = {
  page?: number;
  size?: number;
};

type OrderingParam<T extends string> = {
  ordering?: T | `-${T}`;
};

type FindParam = {
  search?: string;
};

type Image = {
  id: string;
  file: string;
};

export type ResponseGetMarketCars = WithPaginator<{
  id: number;
  title: string;
  cover: Image;
  full_price: string;
  discount_price: string;
  semiannual_txy_profit: string;
  semiannual_usdt_profit: string;
  annual_txy_profit: string;
  annual_usdt_profit: string;
  daily_txy_profit: string;
  daily_usdt_profit: string;
  monthly_txy_profit: string;
  monthly_usdt_profit: string;
  reliability: number;
  is_purchased: boolean;
  purchased_car_id: number;
  is_locked: boolean;
  tasks_to_unlock: Pick<
    Task["task"],
    "id" | "description_ru" | "description_ua" | "description_en"
  >[];
  current_count_per_user: number;
  max_count_per_user: number | null;
}>;

export type ParamsGetMarketCars = PaginatorParams &
  FindParam &
  OrderingParam<
    "id" | "discount_price" | "reliability" | "daily_txy_profit"
  > & {
    exclude?: number;
    only_non_purchased?: boolean;
  };

type ResponseGetUserCars = WithPaginator<{
  id: number;
  title: "string";
  cover: Image;
  reliability: number;
  annual_txy_profit: string;
  annual_usdt_profit: string;
}>;

type Car = {
  annual_txy_profit: string;
  annual_usdt_profit: string;
  id: number;
  title: string;
  description_ru: string;
  description_ua: string;
  description_en: string;
  cover: Image;
  discount_price: number;
  daily_txy_profit: string;
  daily_usdt_profit: string;
  monthly_txy_profit: string;
  monthly_usdt_profit: string;
  semiannual_txy_profit: string;
  semiannual_usdt_profit: string;
  reliability: number;
  current_count_per_user: number;
  max_count_per_user: number | null;
};

export type ResponseGetMarketCarInfo = Car;

export type ResponseGetParkCarInfo = {
  id: number;
  title: string;
  cover: Image;
  cover_with_sticker: Image | null;
  description_ru: string;
  description_ua: string;
  description_en: string;
  daily_txy_profit: string;
  daily_usdt_profit: string;
  monthly_txy_profit: string;
  monthly_usdt_profit: string;
  annual_txy_profit: string;
  annual_usdt_profit: string;
  reliability: number;
  current_count_per_user: number;
  max_count_per_user: number | null;
  sticker_cost: number;
};

type CarsCollectionParam = {
  ordering?: string;
  search?: string;
};

export type ResponseGetParkCarsCollection = {
  id: number;
  car: {
    id: number;
    title: string;
  };
  sequence_id: number;
  cover: Image;
  is_broken: boolean;
  is_stickered: boolean;
};

export type Task = {
  id: number;
  task: {
    id: number;
    title_ru: string;
    title_ua: string;
    title_en: string;
    description_ru: string;
    description_ua: string;
    description_en: string;
    cover: {
      id: string;
      file: string;
    };
    max_progress: number;
  };
  current_progress: number;
  is_performed: boolean;
};

export type ResponseGetProfile = {
  id: number;
  username: string;
  balance: string;
  earnings: string;
  avatar: Image;
  name: string;
  is_beta: boolean;
};

type ResponseGetCarsService = WithPaginator<CarServiceItem>;

type ResponseGetUserTasks = Task[];

export type GameCar = {
  id: number;
  sequence_id: number;
  car: {
    id: number;
    title: string;
  };
  cover: Image;
  ride_end_time: string | null;
  available_txy_to_collect: string | null;
  is_broken: boolean;
  ride_start_time: string | null;
};
export type ResponseGetGameCars = WithPaginator<GameCar>;

type ResponseGetExchanges = WithPaginator<{
  date: string;
  rate: number;
}>;

type ResponseGetCurrentRate = {
  id: number;
  updated_at: string;
  usdt_per_txy: string;
};

export type ExchangesInterval = "day" | "week" | "month" | "year" | "all";

type GetExchangesSpecialParams = {
  interval: ExchangesInterval;
};

export type BreakDownInfo = {
  id: number;
  title_ru: string;
  title_ua: string;
  title_en: string;
  description_ru: string;
  description_ua: string;
  description_en: string;
  cover: Image;
};
export type BreakDown = {
  id: number;
  breakdown: BreakDownInfo;
  repair_cost: string;
  repaired_at: Date | null;
};
export type CarService = {
  id: 0;
  title: string;
};
export type CarServiceItem = {
  id: number;
  car: CarService;
  cover: Image;
  last_breakdown: BreakDown;
  sequence_id: number;
};

type RequestWithdrawal = {
  wallet_address: string;
  pay_currency: PayCurrency;
  amount_in_game_currency: string;
};

type RequestConvertTXY = {
  txy: string;
};

type ResponseConvertTXY = {
  usdt: string;
};
export type ReferralNetworkItem = {
  id: number;
  name: string;
  income_to_referrer_in_game_currency: string;
};

export type ReferralInfo = {
  id: number;
  referral_program_status: {
    id: number;
    title_ru: string;
    title_ua: string;
    title_en: string;
  };
  total_income_from_referrals_in_game_currency: string;
  referral_count: number;
  referral_link: string;
};

export type ReferralsStatus = {
  id: number;
  title_ru: string;
  title_ua: string;
  title_en: string;
  min_referral_count: number;
  max_referral_count: number;
  income_per_referral_in_percent: number;
  cover: Image;
};

export type PaymentType = "deposit" | "withdrawal";

export type PayCurrency = "TON" | "USDT";

type GetTransactionsSpecialParams = {
  type: PaymentType;
  ordering:
    | "create_date"
    | "-create_date"
    | "amount_in_game_currency"
    | "-amount_in_game_currency";
};

export type TransactionStatus =
  | "successful"
  | "waiting"
  | "failed"
  | "under_consideration"
  | "rejected"
  | "processing";

export type ResponseGetTransactions = {
  id: number;
  type: PaymentType;
  status: TransactionStatus;
  pay_currency: PayCurrency;
  amount_in_pay_currency: string;
  amount_in_game_currency: string;
  comment: {
    id: number;
    text: string;
  };
  created_at: string;
};

export type ResponseGetRatingCars = {
  id: number;
  name: string;
  cars_count: number;
};
export type ResponseGetRatingEarnings = Omit<
  ResponseGetRatingCars,
  "cars_count"
> & {
  total_txy_earnings: string;
};

type Rank = {
  rank: number;
};
export type ResponseGetRatingCarsMe = ResponseGetRatingCars & Rank;

export type ResponseGetRatingEarningsMe = ResponseGetRatingEarnings & Rank;

export type ResponseGetGameStatistics = {
  earnings: string;
  txy_to_collect: string;
  ready_to_ride_cars_count: number;
};

export type RequestCreateTransaction = {
  pay_currency: PayCurrency;
  amount_in_game_currency: string;
};

export type ResponseCreateTransaction = {
  uuid: string;
  pay_currency: PayCurrency;
  amount_in_pay_currency: string;
  amount_in_game_currency: string;
  created_at: string;
};
