import { Outlet, matchRoutes, useLocation } from "react-router-dom";
import { AppNavigation } from "../navigation/Navigation";
import { useEffect, useLayoutEffect, useMemo } from "react";
import { routes } from "@/navigation/routes";
import { Header } from "../header/Header";
import {
  PartialSnackbarProps,
  useSnackbar,
} from "../ui/SnackBar/SnackbarContext";
import { useQueryClient } from "@tanstack/react-query";
import { ApiError } from "@/api";
import InsufficientFunds from "@/components/ui/SnackBar/InsufficientFunds/InsufficientFunds";
import ConnectionFailure, {
  imgBgTiers,
  imgExclamationMark,
} from "@/components/ui/SnackBar/ConnectionFailure/ConnectionFailure";
import useNetworkStatus from "@/hooks/useNetworkStatus";
import { useImagePreload } from "@/hooks/useImagePreload";

export function AppLayout() {
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const snackBar = useSnackbar();
  const { isOnline } = useNetworkStatus();

  const lastMatchedRoute = useMemo(
    () => matchRoutes(routes, pathname)!.at(-1)!.route,
    [pathname]
  );

  const lastMatchedRouteLayout = lastMatchedRoute.meta.layout;

  const isHeaderVisible = (["full", "header"] satisfies Layout[]).includes(
    //@ts-expect-error ts(2322)
    lastMatchedRouteLayout
  );

  const isNavigationVisible = (["full", "nav"] satisfies Layout[]).includes(
    //@ts-expect-error ts(2322)
    lastMatchedRouteLayout
  );

  useImagePreload(imgBgTiers, { enabled: true });
  useImagePreload(imgExclamationMark, { enabled: true });

  useLayoutEffect(() => {
    function showErrorSnackbar(apiError: ApiError) {
      snackBar.setShowWithProps(getErrorSnackbarProps(apiError));
      return false;
    }

    queryClient.setDefaultOptions({
      queries: {
        throwOnError: (err) => showErrorSnackbar(err as unknown as ApiError),
      },
      mutations: {
        onError: (err) => showErrorSnackbar(err as unknown as ApiError),
      },
    });
  }, []);

  useEffect(() => {
    if (!isOnline) {
      const err: ApiError = {
        type: "network_error",
        errors: [
          {
            attr: null,
            code: ERROR_CODES.connectionFailure,
            detail: "Problem with internet connection",
          },
        ],
      };
      snackBar.setShowWithProps(getErrorSnackbarProps(err));
    }
  }, [isOnline]);

  return (
    <main className="font-inter flex flex-col  bg-black-footer px-layout text-base" style={{ minHeight: "calc(100dvh + 1px)" }}>
      {isHeaderVisible && <Header />}
      <div className="flex-1 flex flex-col">
        <Outlet />
      </div>
      {isNavigationVisible && <AppNavigation />}
    </main>
  );
}

export type Layout = "header" | "nav" | "empty" | "full";
export type RouteLayoutHandle = {
  layout: Layout;
};

const ERROR_CODES = {
  insufficientFunds: "insufficient_funds",
  connectionFailure: "connection_failure",
};

const getErrorSnackbarProps = (apiError: ApiError): PartialSnackbarProps => {
  const errorCode = apiError?.errors?.[0]?.code;

  if (errorCode === ERROR_CODES.insufficientFunds)
    return { type: "sidebar", children: <InsufficientFunds /> };
  if (errorCode === ERROR_CODES.connectionFailure)
    return { type: "sidebar", children: <ConnectionFailure /> };

  return { type: "text", children: apiError?.errors?.[0]?.detail, isError: true };
};
