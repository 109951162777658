import { ReferralNetworkItem } from "@/api";
import { apiQueries } from "@/api/queries";
import { Button } from "@/components/ui/Button";
import { cn } from "@/utils";
import { TXY } from "@/variables";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const SIZE = 15;

export const ReferralNetwork = () => {
  const { t } = useTranslation("common");

  const [slicedReferrals, setSlicedReferrals] = useState<ReferralNetworkItem[]>(
    []
  );
  const [size, setSize] = useState(SIZE);

  const { data: referrals } = useQuery({
    ...apiQueries.getReferralsList({
      page: 1,
      size: size,
    }),
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (referrals) {
      setSlicedReferrals(referrals?.results.slice(0, size));
    }
  }, [referrals, size]);

  return (
    <>
      <h2 className={"text-base font-semibold mt-5"}>{t("refs.refNetwork")}</h2>
      {!referrals ||
        (referrals?.count === 0 && (
          <p className={"text-gray-middle text-xs mb-8"}>
            {t("refs.refNetworkEmpty")}
          </p>
        ))}
      {referrals && referrals.count > 0 && (
        <div className={"mb-8"}>
          <div className={"bg-black-dark-card rounded-10 p-4 pb-1 mb-5 mt-3"}>
            <table className={"w-full"}>
              <thead>
                <tr className={"text-gray-light font-normal text-sm"}>
                  <th className={"text-start"}>{t("refs.username")}</th>
                  <th className={"text-start"}>{t("refs.yourProfit")}</th>
                </tr>
              </thead>
              <tbody>
                {slicedReferrals.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={cn(
                        "text-sm ",
                        index < referrals.count - 1 &&
                          "border-b border-b-white-20 text-white-light"
                      )}
                    >
                      <td className={"py-3 w-7/12 break-all text-wrap "}>
                        {item.name}
                      </td>
                      <td className={" w-5/12 pl-1.5"}>
                        {item.income_to_referrer_in_game_currency} {TXY}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {referrals.count > size && (
            <Button
              onClick={() => {
                setSize(size + SIZE);
              }}
              className="w-full bg-black-footer text-yellow-primary border border-yellow-primary py-2 mb-5"
            >
              {t("actions.showMore")}
            </Button>
          )}
          {referrals.count > SIZE && referrals.count < size && (
            <Button
              onClick={() => {
                setSize(SIZE);
              }}
              className="w-full bg-black-footer text-yellow-primary border border-yellow-primary py-2 mb-5"
            >
              {t("actions.showLess")}
            </Button>
          )}
        </div>
      )}
    </>
  );
};
