import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { cn } from "@/utils";
import { usePlatform } from "@/hooks/usePlatform";

export const SideBar = ({
  isOpen,
  children,
  setIsOpen,
  withoutPaddings = false,
  withCross = true,
}: {
  isOpen: boolean;
  children: React.ReactNode;
  setIsOpen: (value: boolean) => void;
  withoutPaddings?: boolean;
  withCross?: boolean;
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const { isApplePlatform } = usePlatform();

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);

      setTimeout(() => {
        setIsAnimating(true);
      }, 20);
    } else {
      setIsAnimating(false);

      const timer = setTimeout(() => {
        setIsMounted(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!isMounted) return null;

  return createPortal(
    <>
      <div
        className={cn(
          "fixed inset-0 backdrop-blur-md w-full z-40 bg-gray-dark-input bg-opacity-30",
          isOpen ? "block" : "hidden"
        )}
        onClick={() => setIsOpen(false)}
      />

      <div
        className={cn(
          "fixed bottom-0 right-0 w-full rounded-t-30 z-40 bg-white-light transition-transform duration-500",
          isAnimating ? "translate-y-0" : "translate-y-full",
          { "py-4 px-3": !withoutPaddings },
          {
            "pb-6": !withoutPaddings && isApplePlatform,
          }
        )}
      >
        {withCross && (
          <button
            className="absolute top-5 right-4"
            onClick={() => setIsOpen(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#454545"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </button>
        )}
        {children}
      </div>
    </>,
    document.querySelector("#root")!
  );
};
