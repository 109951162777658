import { ParamsGetMarketCars, ResponseGetMarketCars } from "@/api";
import { useInvalidateQueriesByKeys } from "@/api/apiUtils";
import { apiMutations, apiQueries, QueryKey } from "@/api/queries";
import { CarProfitList } from "@/components/car/CarProfitList";
import { CardBlocker } from "@/components/cards/CardBlocker/CardBlocker";
import { Link } from "@/components/Link/Link";
import { SideBar } from "@/components/sideBar/SideBar";
import { Button } from "@/components/ui/Button";
import { Head } from "@/components/ui/Head";
import {
  generateDynamicRoute as generateDynamicRoutePath,
  ROUTE,
} from "@/config/navigation";
import { TranslateNamespaces } from "@/localization/localizationConfig";
import { COMPETITION_CAR_NAME, TXY, USDT } from "@/variables";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { LinkProps, useNavigate, useSearchParams } from "react-router-dom";
import carImg from "./competition-Mersedes-E-klass.webp";
import competitionBg from "./competition-bg.webp";
import { CompetitionSidebar } from "./CompetitionSidebar";
import { isTodayInCompetitionRange } from "@/utils";

type OrderingSearchParams = Extract<
  Required<ParamsGetMarketCars>["ordering"],
  "discount_price" | "-discount_price" | "-daily_txy_profit" | "-reliability"
>;

export function MarketPage() {
  const [currentOrdering] = useMarketOrderingSearchParams();
  const { t } = useTranslation("common");

  const {
    data: marketCars,
    isSuccess,
    refetch: refetchMarketCars,
  } = useQuery(
    apiQueries.getMarketCars({
      ordering: currentOrdering,
      size: 100,
    })
  );

  const { userProfile } = useInvalidateQueriesByKeys([QueryKey.userProfile]);

  const { mutateAsync: purchaseCar, isPending: isPendingPurchase } =
    useMutation(apiMutations.purchaseCar);

  const [buyCarSideBar, setSideBarBuyCar] = useState<
    ResponseGetMarketCars["results"][number] | null
  >(null);

  const [isOpen, setIsOpen] = useState(false);

  const [isCompetitionInfoOpen, setIsCompetitionInfoOpen] = useState(false);
  // const [competitionCarId, setCompetitionCarId] = useState<number | null>(null);

  async function purchaseCarHandler(id: number) {
    await purchaseCar(id);
    refetchMarketCars();
    userProfile.invalidate();
    setIsOpen(false);
  }

  const showCompetitionInfo = (e: React.MouseEvent /*, id: number*/) => {
    e.preventDefault();
    e.stopPropagation();

    // setCompetitionCarId(id);
    setIsCompetitionInfoOpen(true);
  };

  const hideCompetitionInfo = () => {
    setIsCompetitionInfoOpen(false);
    // setCompetitionCarId(null);
  };

  return (
    <>
      <div>
        <OrderingSelect />

        {isSuccess && (
          <>
            <ul className="space-y-2 mt-3">
              {marketCars?.results.map((car) => {
                const isCompetitionCar = car.title === COMPETITION_CAR_NAME && isTodayInCompetitionRange();

                return (
                  <CardMarketCar
                    key={car.id}
                    onBuyClick={() => {
                      setSideBarBuyCar(car);
                      setIsOpen(true);
                    }}
                    car={car}
                    isCompetitionCar={isCompetitionCar}
                    showCompetitionInfo={
                      isCompetitionCar ? showCompetitionInfo : undefined
                    }
                  />
                );
              })}
            </ul>
            {Boolean(buyCarSideBar) && (
              <SideBar isOpen={isOpen} setIsOpen={setIsOpen}>
                <Head as={"h2"} className="mt-3">
                  {t("actions.buy")} {buyCarSideBar!.title} ?
                </Head>
                <h3 className="text-sm font-semibold mb-2 mt-3">
                  {t("car.profit")}:
                </h3>
                <CarProfitList
                  noIcons
                  className="text-sm font-normal text-gray-dark"
                  profits={{
                    perDay: {
                      txy: buyCarSideBar!.daily_txy_profit,
                      usdt: buyCarSideBar!.daily_usdt_profit,
                    },
                    perMonth: {
                      txy: buyCarSideBar!.monthly_txy_profit,
                      usdt: buyCarSideBar!.monthly_usdt_profit,
                    },
                    perYear: {
                      txy: buyCarSideBar!.annual_txy_profit,
                      usdt: buyCarSideBar!.annual_usdt_profit,
                    },
                  }}
                />
                <hr className="my-3 border-black-12" />
                <h3 className="text-sm font-semibold mt-3">
                  {t("car.reliability")}:
                </h3>
                <span className="text-sm font-normal text-gray-dark">
                  {buyCarSideBar!.reliability}%
                </span>

                <img
                  className="w-full aspect-video object-contain"
                  src={buyCarSideBar!.cover.file}
                  alt={buyCarSideBar!.title}
                />

                <Button
                  disabled={isPendingPurchase}
                  onClick={() => purchaseCarHandler(buyCarSideBar!.id)}
                  className="w-full"
                  variant="dark"
                >
                  {+buyCarSideBar!.discount_price === 0
                    ? t("actions.getForFree")
                    : `${t("actions.pay")} ${
                        buyCarSideBar!.discount_price
                      } ${TXY}`}
                </Button>
              </SideBar>
            )}
          </>
        )}
      </div>

      <SideBar
        isOpen={isCompetitionInfoOpen}
        setIsOpen={hideCompetitionInfo}
        withCross
      >
        <CompetitionSidebar />
      </SideBar>
    </>
  );
}

function CardMarketCar(p: {
  car: ResponseGetMarketCars["results"][number];
  onBuyClick: () => void;
  isCompetitionCar?: boolean;
  showCompetitionInfo?: (e: React.MouseEvent /*, id: number*/) => void;
}) {
  const { discount_price, is_purchased, full_price, is_locked } = p.car;
  const navigate = useNavigate();

  const { t } = useTranslation("common");

  const [LinkResolver, resolverProps] = is_locked
    ? [Fragment, {}]
    : [
        Link,
        {
          to: generateDynamicRoutePath(
            is_purchased ? ROUTE.CAR_INFO : ROUTE.MARKET_CAR_INFO,
            p.car.id
          ),
        } satisfies LinkProps,
      ];

  return (
    <li
      className={
        "relative rounded-10 bg-gray-10 p-4 overflow-hidden border border-card-border"
      }
      style={
        p.isCompetitionCar
          ? {
              backgroundImage: `url(${competitionBg})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "0px 10px",
            }
          : {}
      }
    >
      {/* @ts-expect-error no resolve props type */}
      <LinkResolver {...resolverProps}>
        <h2 className="flex items-center justify-between text-yellow-primary text-lg font-bold">
          <div className="flex items-center gap-1.5">
            {p.car.title} ({p.car.current_count_per_user}/
            {p.car.max_count_per_user ?? <>&#8734;</>})
            {p.isCompetitionCar ? (
              <button
                type="button"
                onClick={(e) =>
                  p.showCompetitionInfo && p.showCompetitionInfo(e)
                }
              >
                <svg
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.4984 0.5C16.2978 0.5 20.999 5.2012 20.999 11.0005C20.999 16.7988 16.2978 21.5 10.4984 21.5C4.69909 21.5 1.75838e-07 16.7988 1.75838e-07 11.0005C-0.00104967 5.2012 4.69909 0.5 10.4984 0.5Z"
                    fill="black"
                  />
                  <path
                    d="M10.4999 5.22608C10.3242 5.22126 10.1494 5.25171 9.98568 5.31564C9.82198 5.37956 9.67277 5.47567 9.54686 5.59826C9.42095 5.72086 9.32091 5.86746 9.25264 6.02939C9.18437 6.19133 9.14927 6.3653 9.14941 6.54104C9.14955 6.71677 9.18493 6.8907 9.25346 7.05252C9.32198 7.21434 9.42226 7.36078 9.54837 7.48318C9.67447 7.60557 9.82384 7.70144 9.98764 7.7651C10.1514 7.82877 10.3263 7.85894 10.502 7.85384C10.844 7.84377 11.1686 7.70071 11.4069 7.45508C11.6451 7.20945 11.7781 6.8806 11.7777 6.53843C11.7773 6.19625 11.6435 5.86773 11.4047 5.62267C11.1659 5.37761 10.8409 5.23533 10.4988 5.22608H10.4999ZM10.4946 9.16194C10.2374 9.16229 9.98917 9.25711 9.79718 9.42838C9.6052 9.59966 9.48279 9.83547 9.4532 10.0911L9.44585 10.2128L9.45005 15.9891L9.45635 16.1119C9.48595 16.3679 9.6087 16.6041 9.80121 16.7755C9.99373 16.9469 10.2426 17.0414 10.5003 17.0412C10.758 17.0409 11.0067 16.9459 11.1989 16.7741C11.391 16.6023 11.5133 16.3659 11.5424 16.1098L11.5487 15.987L11.5445 10.2118L11.5371 10.089C11.5068 9.83359 11.3838 9.59824 11.1914 9.42756C10.9991 9.25688 10.7508 9.16274 10.4936 9.16299L10.4946 9.16194Z"
                    fill="#FFD304"
                  />
                </svg>
              </button>
            ) : null}
          </div>
          <svg
            className="size-9 p-2 bg-yellow-primary rounded-full"
            width="20"
            height="13"
            viewBox="0 0 20 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.2432 7.08827C19.5681 6.76337 19.5681 6.23662 19.2432 5.91173L13.9488 0.617324C13.6239 0.292433 13.0972 0.292433 12.7723 0.617324C12.4474 0.942215 12.4474 1.46897 12.7723 1.79386L17.4784 6.5L12.7723 11.2061C12.4474 11.531 12.4474 12.0578 12.7723 12.3827C13.0972 12.7076 13.6239 12.7076 13.9488 12.3827L19.2432 7.08827ZM0.345093 7.33194L18.655 7.33193L18.655 5.66806L0.345093 5.66806L0.345093 7.33194Z"
              fill="#111112"
            />
          </svg>
        </h2>
        <div className="text-sm font-medium leading-normal">
          <span className="text-gray-light">{t("car.profit")}: </span>
          {`${t("car.upTo")} ${p.car.annual_txy_profit} ${TXY} (${
            p.car.annual_usdt_profit
          } ${USDT})`}
          <br />
          <span className="text-gray-light">{t("car.reliability")}: </span>
          {p.car.reliability}%
        </div>

        <div className="relative">
          <div className="absolute left-0 right-0 top-6 mx-auto w-5/6 h-1/5 bg-yellow-primary bg-opacity-45 rounded-[50%] blur-2xl" />
          <img
            className="relative z-10 aspect-video -my-4 w-full object-contain"
            src={p.isCompetitionCar ? carImg : p.car.cover.file}
            alt={p.car.title}
          />
        </div>
      </LinkResolver>

      <Button
        className="w-full whitespace-nowrap rounded-8 relative z-10"
        variant={is_purchased ? "green" : "dark"}
        onClick={
          is_purchased
            ? () => navigate(resolverProps.to!)
            : !is_locked
            ? p.onBuyClick
            : undefined
        }
      >
        {is_purchased ? (
          t("market.inPark")
        ) : +discount_price === 0 ? (
          t("actions.getForFree")
        ) : (
          <>
            {t("actions.buyFor")} {discount_price} {TXY}
            {full_price !== discount_price && (
              <span className="line-through pl-1 text-gray-middle text-opacity-55">
                {full_price} {TXY}
              </span>
            )}
          </>
        )}
      </Button>
      {is_locked && (
        <CardBlocker
          variant="needUnlock"
          tasksToUnlock={p.car.tasks_to_unlock}
        />
      )}
    </li>
  );
}

const DEFAULT_PARAMS: { ordering: OrderingSearchParams } = {
  ordering: "discount_price",
};

const ORDERING_TITLE_BY_SEARCH_PARAM = {
  discount_price: "discountPrice",
  "-discount_price": "-discountPrice",
  "-daily_txy_profit": "dailyTxyProfit",
  "-reliability": "reliability",
} satisfies Record<
  OrderingSearchParams,
  keyof TranslateNamespaces["common"]["market"]["ordering"]
>;

const ORDERING_ENTRIES = Object.entries(ORDERING_TITLE_BY_SEARCH_PARAM) as [
  keyof typeof ORDERING_TITLE_BY_SEARCH_PARAM,
  (typeof ORDERING_TITLE_BY_SEARCH_PARAM)[keyof typeof ORDERING_TITLE_BY_SEARCH_PARAM]
][];

function useMarketOrderingSearchParams(): [
  OrderingSearchParams,
  (value: OrderingSearchParams) => void
] {
  const [searchParams, setSearchParams] = useSearchParams(DEFAULT_PARAMS);

  const currentOrdering = searchParams.get("ordering") as OrderingSearchParams;

  function setOrdering(value: OrderingSearchParams) {
    setSearchParams({ ordering: value });
  }

  return [currentOrdering, setOrdering];
}

function OrderingSelect() {
  const [currentOrdering, setOrdering] = useMarketOrderingSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation("common");

  return (
    <>
      <button
        className="flex items-center justify-between bg-gray-10 rounded-10 font-medium px-4 py-2 text-xs w-full"
        onClick={() => setIsOpen(true)}
      >
        {`${t("market.sortBy")}: ${t(
          `market.ordering.${ORDERING_TITLE_BY_SEARCH_PARAM[currentOrdering]}`
        )}`}

        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.1506 15.6987L13.8493 12L10.1506 8.30133"
            stroke="#FEFEFE"
            strokeWidth="1.2329"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <SideBar {...{ isOpen, setIsOpen }}>
        <Head className="text-center mt-2.5 mb-3">{t("market.sortBy")}</Head>
        <ul className="divide-y divide-black-dark divide-opacity-10">
          {ORDERING_ENTRIES.map(([orderingParam, translateTitleField]) => {
            const _isActive = currentOrdering === orderingParam;
            return (
              <li key={orderingParam}>
                <button
                  className="flex w-full items-center justify-between py-3 text-sm text-gray-dark-input pr-2"
                  onClick={() => {
                    setOrdering(orderingParam);
                    setIsOpen(false);
                  }}
                >
                  {t(`market.ordering.${translateTitleField}`)}
                  {_isActive && (
                    <svg
                      className="w-3 h-auto"
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.7 1.20039C13.3 0.800391 12.7 0.800391 12.3 1.20039L4.8 8.70039L1.7 5.60039C1.3 5.20039 0.7 5.20039 0.3 5.60039C-0.1 6.00039 -0.1 6.60039 0.3 7.00039L4.1 10.8004C4.3 11.0004 4.5 11.1004 4.8 11.1004C5.1 11.1004 5.3 11.0004 5.5 10.8004L13.7 2.60039C14.1 2.20039 14.1 1.60039 13.7 1.20039Z"
                        fill="#2AA415"
                      />
                    </svg>
                  )}
                </button>
              </li>
            );
          })}
        </ul>
      </SideBar>
    </>
  );
}
