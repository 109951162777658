import { apiQueries } from "@/api/queries";
import { useQuery } from "@tanstack/react-query";

import carImgForEmptyList from "./car.svg";

import { EmptyListInformer } from "@/components/ui/EmptyListInformer";
import { CardParkCar } from "@/components/cards/CardParkCar/CardParkCar";
import { useTranslation } from "react-i18next";
import { Link } from "@/components/Link/Link";
import { ROUTE, generateDynamicRoute } from "@/config/navigation";
import carImg from "../market/competition-Mersedes-E-klass.webp";
import { useState } from "react";
import { SideBar } from "@/components/sideBar/SideBar";
import { CompetitionSidebar } from "../market/CompetitionSidebar";
import { COMPETITION_CAR_NAME } from "@/variables";
import { isTodayInCompetitionRange } from "@/utils";

export function ParkPage() {
  const { t } = useTranslation("common");
  const { data: carsData } = useQuery(apiQueries.getUserParkCars);

  const [isCompetitionInfoOpen, setIsCompetitionInfoOpen] = useState(false);
  // const [competitionCarId, setCompetitionCarId] = useState<number | null>(null);

  const showCompetitionInfo = (e: React.MouseEvent /*, id: number*/) => {
    e.preventDefault();
    e.stopPropagation();

    // setCompetitionCarId(id);
    setIsCompetitionInfoOpen(true);
  };

  const hideCompetitionInfo = () => {
    setIsCompetitionInfoOpen(false);
    // setCompetitionCarId(null);
  };

  return (
    <>
      <div className="flex-1 flex flex-col">
        {carsData?.results.length === 0 && (
          <EmptyListInformer
            img={carImgForEmptyList}
            text={t("park.emptyParkText")}
          />
        )}

        <ul className="flex flex-col gap-2">
          {carsData?.results.map(
            ({
              id,
              title,
              cover,
              reliability,
              annual_txy_profit,
              annual_usdt_profit,
            }) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const isCompetitionCar = title === COMPETITION_CAR_NAME && isTodayInCompetitionRange();

              return (
                <Link key={id} to={generateDynamicRoute(ROUTE.CAR_INFO, id)}>
                  <CardParkCar
                    id={id}
                    name={title}
                    profitTXY={annual_txy_profit}
                    profitUSDT={annual_usdt_profit}
                    reliability={reliability}
                    // image={cover.file}
                    image={isCompetitionCar ? carImg : cover.file}
                    isCompetitionCar={isCompetitionCar}
                    showCompetitionInfo={
                      isCompetitionCar ? showCompetitionInfo : undefined
                    }
                  />
                </Link>
              );
            }
          )}
        </ul>
      </div>

      <SideBar
        isOpen={isCompetitionInfoOpen}
        setIsOpen={hideCompetitionInfo}
        withCross
      >
        <CompetitionSidebar />
      </SideBar>
    </>
  );
}
