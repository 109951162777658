import { useTranslation } from "react-i18next";
import { HeaderScreen } from "../HeaderScreen";
import { BasicInfo } from "./basicInfo/BasicInfo";

import { ReferralInfo } from "./ReferralInfo";
import { ReferralNetwork } from "./ReferralNetwork";

export function ReferralProgramPage() {
  const { t } = useTranslation("common");
  const title = t("profile.links.refs");

  return (
    <>
      <HeaderScreen isBack title={title} />
      <BasicInfo />
      <ReferralInfo />
      <ReferralNetwork />
    </>
  );
}
