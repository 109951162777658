export const SuccessIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_3791_56591)">
<rect x="4.92969" y="6.19434" width="14.8408" height="11.6787" fill="#FEFEFE"/>
<path d="M21.688 11.999C21.688 17.3493 17.3508 21.6865 12.0005 21.6865C6.65021 21.6865 2.31299 17.3493 2.31299 11.999C2.31299 6.64875 6.65021 2.31152 12.0005 2.31152C17.3508 2.31152 21.688 6.64875 21.688 11.999ZM10.8799 17.1285L18.0674 9.94098C18.3115 9.69691 18.3115 9.30117 18.0674 9.05711L17.1836 8.17324C16.9395 7.92914 16.5438 7.92914 16.2997 8.17324L10.438 14.0349L7.70131 11.2982C7.45725 11.0541 7.0615 11.0541 6.8174 11.2982L5.93353 12.1821C5.68947 12.4261 5.68947 12.8219 5.93353 13.0659L9.99603 17.1284C10.2401 17.3725 10.6358 17.3725 10.8799 17.1285Z" fill="#2AA415"/>
</g>
<defs>
<clipPath id="clip0_3791_56591">
<rect width="20" height="20" fill="white" transform="translate(2 2)"/>
</clipPath>
</defs>
</svg>
