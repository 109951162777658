import { apiQueries } from "@/api/queries";
import { CoinIcon } from "@/components/ui/CoinIcon";
import { cn } from "@/utils";
import { TXY } from "@/variables";
import { useQuery } from "@tanstack/react-query";

export function BalanceField({ className }: { className?: string }) {
  const { data } = useQuery(apiQueries.getProfile);

  return (
    <div
      className={cn(
        "bg-gray-10 rounded-full font-semibold h-9 px-2 text-xs flex items-center gap-1",
        className
      )}
    >
      <CoinIcon />
      {data?.balance ?? "0"} {TXY}
    </div>
  );
}
