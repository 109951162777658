import arrowRightIcon from "./arrow-right.png";
import { Card } from "../Card/Card";
import { CardBlocker, CardBlockerProps } from "../CardBlocker/CardBlocker";
import { imgTire } from "@/assets";
import { TXY, USDT } from "@/variables";
import { useTranslation } from "react-i18next";
import competitionBg from "../../pages/market/competition-bg.webp";

export function CardParkCar(p: {
  id: number;
  name: string;
  image: string;
  profitTXY: string;
  profitUSDT: string;
  reliability: number;
  cardBlockerProps?: CardBlockerProps;
  isCompetitionCar?: boolean;
  showCompetitionInfo?: (e: React.MouseEvent/*, id: number*/) => void;
}) {
  const { t } = useTranslation("common");
  return (
    <div
      className="relative overflow-hidden rounded-10 border border-black-dark-card flex flex-col"
      // style={
      //   p.isCompetitionCar
      //     ? {
      //         backgroundImage: `url(${competitionBg})`,
      //         backgroundSize: "contain",
      //       }
      //     : {}
      // }
    >
        {p.isCompetitionCar && (
          <img
            src={competitionBg}
            className="absolute z-10 -top-16 "
          />
        )}
      <h2
        style={{
          backgroundImage: `url(${arrowRightIcon})`,
          backgroundSize: "1.6rem",
        }}
        className="m-3 mr-4 mb-0 font-inter font-bold text-lg bg-right bg-no-repeat flex gap-1.5  z-10 relative"
      >
        {p.name}
        {p.isCompetitionCar && (
          <button
            type="button"
            onClick={(e) =>
              p.showCompetitionInfo && p.showCompetitionInfo(e)
            }
          >
            <svg
              width="21"
              height="22"
              viewBox="0 0 21 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4984 0.5C16.2978 0.5 20.999 5.2012 20.999 11.0005C20.999 16.7988 16.2978 21.5 10.4984 21.5C4.69909 21.5 1.75838e-07 16.7988 1.75838e-07 11.0005C-0.00104967 5.2012 4.69909 0.5 10.4984 0.5Z"
                fill="black"
              />
              <path
                d="M10.4999 5.22608C10.3242 5.22126 10.1494 5.25171 9.98568 5.31564C9.82198 5.37956 9.67277 5.47567 9.54686 5.59826C9.42095 5.72086 9.32091 5.86746 9.25264 6.02939C9.18437 6.19133 9.14927 6.3653 9.14941 6.54104C9.14955 6.71677 9.18493 6.8907 9.25346 7.05252C9.32198 7.21434 9.42226 7.36078 9.54837 7.48318C9.67447 7.60557 9.82384 7.70144 9.98764 7.7651C10.1514 7.82877 10.3263 7.85894 10.502 7.85384C10.844 7.84377 11.1686 7.70071 11.4069 7.45508C11.6451 7.20945 11.7781 6.8806 11.7777 6.53843C11.7773 6.19625 11.6435 5.86773 11.4047 5.62267C11.1659 5.37761 10.8409 5.23533 10.4988 5.22608H10.4999ZM10.4946 9.16194C10.2374 9.16229 9.98917 9.25711 9.79718 9.42838C9.6052 9.59966 9.48279 9.83547 9.4532 10.0911L9.44585 10.2128L9.45005 15.9891L9.45635 16.1119C9.48595 16.3679 9.6087 16.6041 9.80121 16.7755C9.99373 16.9469 10.2426 17.0414 10.5003 17.0412C10.758 17.0409 11.0067 16.9459 11.1989 16.7741C11.391 16.6023 11.5133 16.3659 11.5424 16.1098L11.5487 15.987L11.5445 10.2118L11.5371 10.089C11.5068 9.83359 11.3838 9.59824 11.1914 9.42756C10.9991 9.25688 10.7508 9.16274 10.4936 9.16299L10.4946 9.16194Z"
                fill="#FFD304"
              />
            </svg>
          </button>
        )}
      </h2>
      <img
        src={p.image}
        height={200}
        className="grow w-4/5 aspect-[26/12] z-10 mx-auto object-contain mb-1"
      />

      <div className="flex gap-1.5 z-10 p-1.5 pt-0 whitespace-pre-wrap font-medium text-xs leading-normal">
        <Card className="grow pr-6">
          <div className="text-gray-light">{t("car.profit")}</div>
          {`${t("car.upTo")} ${p.profitTXY} ${TXY} (${p.profitUSDT} ${USDT})`}
        </Card>
        <Card className="pr-4">
          <div className="text-gray-light">{t("car.reliability")}</div>
          {p.reliability}%
        </Card>
      </div>
      <div
        style={{
          backgroundImage: `url(${imgTire})`,
        }}
        className="absolute inset-0 top-1/2 bg-black-dark bg-no-repeat bg-contain"
      />
      {p.cardBlockerProps && <CardBlocker {...p.cardBlockerProps} />}
    </div>
  );
}
