export const ErrorIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_3791_56692)">
<rect x="4.92969" y="6.19434" width="14.8408" height="11.6787" fill="#FF3B30"/>
<path d="M12.0005 21.6865C17.3508 21.6865 21.688 17.3493 21.688 11.999C21.688 6.64875 17.3508 2.31152 12.0005 2.31152C6.65021 2.31152 2.31299 6.64875 2.31299 11.999C2.31299 17.3493 6.65021 21.6865 12.0005 21.6865Z" fill="#FF3B30"/>
</g>
<path d="M14.9998 14.9329L9.06689 9" stroke="white" strokeWidth="2.51394" strokeMiterlimit="10" strokeLinecap="square" strokeLinejoin="round"/>
<path d="M14.9329 9.06738L9 15.0003" stroke="white" strokeWidth="2.51394" strokeMiterlimit="10" strokeLinecap="square" strokeLinejoin="round"/>
<defs>
<clipPath id="clip0_3791_56692">
<rect width="20" height="20" fill="white" transform="translate(2 2)"/>
</clipPath>
</defs>
</svg>
