import { CoinIcon } from "@/components/ui/CoinIcon";
import { cn } from "@/utils";
import imgCar from "./assets/car.webp";
import imgFirst from "./assets/1rd_place.webp";
import imgSecond from "./assets/2rd_place.webp";
import imgThird from "./assets/3rd_place.webp";
import { TXY } from "@/variables";
import {
  ResponseGetRatingCars,
  ResponseGetRatingCarsMe,
  ResponseGetRatingEarnings,
  ResponseGetRatingEarningsMe,
} from "@/api";

const positionImgs = [imgFirst, imgSecond, imgThird];

export function ListItem(p: {
  position: number;
  user:
    | ResponseGetRatingCars
    | ResponseGetRatingEarnings
    | ResponseGetRatingCarsMe
    | ResponseGetRatingEarningsMe;
  isCurrentUser: boolean;
  inViewRef: ((node?: Element | null | undefined) => void) | null;
  isCarsStatus: boolean;
  isApplePlatform?: boolean;
  isHighlighted?: boolean;
}) {
  return (
    <>
      <li
        key={p.user.id}
        className={cn(`py-1`, {
          "py-2.5": p.isHighlighted && p.isCurrentUser && !p.isApplePlatform,
          "pt-2.5 pb-5":
            p.isHighlighted && p.isCurrentUser && p.isApplePlatform,
          "bg-black-footer fixed bottom-0 -mx-layout px-layout mt-1 w-full":
            p.isHighlighted && p.isCurrentUser,
        })}
        style={{
          boxShadow:
            p.isHighlighted && p.isCurrentUser
              ? "0px -4px 10.1px 0px #FFD30433"
              : "",
        }}
        ref={p.isCurrentUser ? p.inViewRef : null}
      >
        <div
          className={cn("flex gap-3 p-1.5 bg-black-dark-card rounded-10", {
            "border border-white-20": p.isCurrentUser,
          })}
        >
          <div
            className={cn(
              "bg-gray-dark-image rounded-6 p-1 w-11 h-11 flex items-center justify-center  bg-no-repeat aspect-square",
              p.position > 999 ? "text-sm" : "text-lg"
            )}
            style={{
              backgroundImage:
                p.position > 3 ? "" : `url(${positionImgs[p.position - 1]})`,
              backgroundSize: p.position > 3 ? "" : "90%",
              backgroundPosition: p.position > 3 ? "" : "center",
            }}
          >
            {p.position > 3 ? p.position : ""}
          </div>
          <div>
            <div className="font-medium text-sm mb-1">{p.user.name}</div>
            <div className="flex items-center gap-1.5 text-white-65 font-bold">
              {p.isCarsStatus ? (
                <div
                  className="w-4 h-4"
                  style={{
                    backgroundImage: `url(${imgCar})`,
                    backgroundSize: "99%",
                  }}
                ></div>
              ) : (
                <CoinIcon size="small" />
              )}

              {p.isCarsStatus && "cars_count" in p.user
                ? p.user.cars_count ?? 0
                : "total_txy_earnings" in p.user
                ? `${p.user.total_txy_earnings ?? 0} ${TXY}`
                : 0}
            </div>
          </div>
        </div>
      </li>
    </>
  );
}
