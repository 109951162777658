import { cn } from "@/utils";

export function Card(p: {
  children: React.ReactNode;
  variant?: "mini";
  className?: string;
}) {
  return (
    <div
      className={cn("rounded-6 px-2.5 py-1.5 bg-black-dark-card", p.className)}
    >
      {p.children}
    </div>
  );
}
