import { Button } from "@/components/ui/Button";
import { TXY } from "@/variables";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

export function GameEarnings({
  earnings,
  setIsTransferOpen,
}: {
  earnings: number;
  setIsTransferOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation("common");

  return (
    <div className="bg-gray-light-01 py-2 pl-3 pr-2 rounded-10 font-inter text-[0.8rem] mb-3 flex items-center gap-2">
      <div className="flex gap-1 flex-wrap">
        <span className="font-black">{t("game.footer.my-income")}:</span>{" "}
        <span className="font-bold">
          {earnings ?? 0} {TXY}
        </span>
      </div>
      <Button
        onClick={() => {
          setIsTransferOpen(true);
        }}
        className="ml-auto text-white-light p-2 text-xs rounded-8 text-nowrap"
        variant="dark"
      >
        {t("game.footer.transfer-to-balance")}
      </Button>
    </div>
  );
}
