import { invalidateByPredicate } from "@/api/apiUtils";
import { QueryKey } from "@/api/queries";
import { useQueryClient } from "@tanstack/react-query";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useRef,
} from "react";
import { GameContext } from "../GamePage";

export const SuccessMutation = createContext({} as SuccessMutationValue);

type SuccessMutationValue = {
  invalidateProfile: () => void;
  invalidateCars: () => void;
  invalidateCarsWithThrottle: () => void;
  onSuccessSendForWork: (id: number) => void;
  onSuccessCollectIncome: (id: number) => void;
  onSuccessTransfer: () => void;
  onSuccessWork: (id: number) => void;
};

const useThrottle = <T extends (...args: unknown[]) => unknown>(
  callback: T,
  delay: number
) => {
  const lastCallRef = useRef(0);

  const throttledFunction = (...args: Parameters<T>) => {
    const now = Date.now();
    if (now - lastCallRef.current >= delay) {
      lastCallRef.current = now;
      callback(...args);
    }
  };

  return throttledFunction;
};

export const SuccessMutationProvider = ({
  children,
  setIsTransferOpen,
}: {
  children: React.ReactNode;
  setIsTransferOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const queryClient = useQueryClient();

  const { sendMapMessage, setCarIntervals } = useContext(GameContext);

  const invalidateCars = () => {
    queryClient.invalidateQueries({
      predicate: invalidateByPredicate([QueryKey.gameCars]),
    });
  };

  const invalidateCarsWithThrottle = useThrottle(() => {
    queryClient.invalidateQueries({
      predicate: invalidateByPredicate([QueryKey.gameCars]),
    });
  }, 500);

  const invalidateGameStatistic = () => {
    queryClient.invalidateQueries({
      predicate: invalidateByPredicate([QueryKey.gameStatistics]),
    });
  };
  const invalidateProfile = () => {
    queryClient.invalidateQueries({
      predicate: invalidateByPredicate([QueryKey.userProfile]),
    });
  };

  const onSuccessCollectIncome = (id: number) => {
    invalidateProfile();
    invalidateGameStatistic();
    invalidateCarsWithThrottle();
    setCarIntervals({
      id,
      interval: 0,
      type: "edit",
    });
  };

  const onSuccessSendForWork = (id: number) => {
    sendMapMessage("AddCar", id + 100); // добавляем 100, т.к. меньше 100 и 100 - это для zoom
    invalidateGameStatistic();
    invalidateCarsWithThrottle();
  };

  const onSuccessTransfer = () => {
    invalidateProfile();
    invalidateGameStatistic();
    invalidateCars();
    setIsTransferOpen(false);
  };

  const onSuccessWork = (id: number) => {
    invalidateCarsWithThrottle();
    invalidateGameStatistic();
    sendMapMessage("RemoveCar", id + 100); // добавляем 100, т.к. меньше 100 и 100 - это для zoom
  };

  return (
    <SuccessMutation.Provider
      value={{
        invalidateProfile,
        invalidateCars,
        invalidateCarsWithThrottle,
        onSuccessSendForWork,
        onSuccessCollectIncome,
        onSuccessTransfer,
        onSuccessWork,
      }}
    >
      {children}
    </SuccessMutation.Provider>
  );
};
