import { GameCar } from "@/api";
import { Button } from "@/components/ui/Button";
import { TXY } from "@/variables";
import { BusyTimerButton } from "./BusyTimerButton";
import { useTranslation } from "react-i18next";
import { cn } from "@/utils";
import { imgWrenches } from "@/assets";
import { useMutation } from "@tanstack/react-query";
import { Spinner } from "@/components/ui/Spinner";
import { apiMutations } from "@/api/queries";
import { useContext, useState } from "react";
import { SuccessMutation } from "./SuccessMutationContext";

export function ParkCard({ car }: { car: GameCar }) {
  const { t } = useTranslation("common");

  const { onSuccessCollectIncome, onSuccessSendForWork } =
    useContext(SuccessMutation);
  const [isLoadingCollect, setIsLoadingCollect] = useState(false);

  const [isLoadingSend, setIsLoadingSend] = useState(false);

  const isFree = !car.available_txy_to_collect && !car.ride_end_time;
  const isWithProfit = !car.ride_end_time && car.available_txy_to_collect;
  const isBusy = car.ride_end_time && !car.available_txy_to_collect;
  const isBroken =
    car.is_broken && !car.ride_end_time && !car.available_txy_to_collect;

  const collectIncome = useMutation({
    ...apiMutations.collectIncomeFromGar,
    onSuccess: (_, id) => onSuccessCollectIncome(id),
    onError: () => setIsLoadingCollect(false),
  });

  const onCollectIncome = (id: number) => {
    if (id) {
      setIsLoadingCollect(true);
      collectIncome.mutateAsync(id);
    }
  };

  const sendForWork = useMutation({
    ...apiMutations.sendCarForWork,
    onSuccess: (_, id) => onSuccessSendForWork(id),
    onError: () => setIsLoadingSend(false),
  });

  const onSendForWork = (id: number) => {
    if (id) {
      setIsLoadingSend(true);
      sendForWork.mutateAsync(id);
    }
  };

  return (
    <li className="relative">
      {isBroken && (
        <div className="absolute inset-0 z-10 flex flex-col justify-center items-center">
          <div
            className="size-12 bg-yellow-primary rounded-full bg-center bg-no-repeat bg-contain mb-4 shrink-0"
            style={{
              backgroundImage: `url(${imgWrenches})`,
              backgroundSize: "75%",
            }}
          />
          <p className="font-inter text-xs font-semibold flex flex-col items-center">
            <span> {car.car.title}</span>
            <span>{t("game.footer.broken")}</span>
          </p>
        </div>
      )}
      <div
        className={cn(
          { "blur-lg pointer-events-none": isBroken },
          "bg-gray-light-01 p-3 rounded-10 font-inter text-xs"
        )}
      >
        <h3 className="font-bold">
          {car.sequence_id}.{car.car.title}
        </h3>

        <div className="bg-gray-light-02 rounded-8 my-1.5 py-1.5 h-[75px]">
          <img src={car.cover.file} />
        </div>

        {isFree && (
          <Button
            className="w-full py-2 rounded-8 text-xs flex justify-center"
            onClick={(e) => {
              e.currentTarget.disabled = true;
              onSendForWork(car.id);
            }}
          >
            {isLoadingSend ? (
              <Spinner size="small" variant="dark" />
            ) : (
              t("game.footer.send-to-work")
            )}
          </Button>
        )}

        {isWithProfit && (
          <Button
            className="w-full py-2 px-1 rounded-8 text-xs flex justify-center"
            variant="green"
            onClick={(e) => {
              e.currentTarget.disabled = true;
              onCollectIncome(car.id);
            }}
          >
            {isLoadingCollect ? (
              <Spinner size="small" variant="dark" />
            ) : (
              `${t("game.footer.collect")} +${
                car.available_txy_to_collect
              } ${TXY}`
            )}
          </Button>
        )}

        {isBusy && (
          <BusyTimerButton id={car.id} timerEndTime={car.ride_end_time ?? ""} />
        )}
      </div>
    </li>
  );
}
