"use client";

import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { Header } from "./Header";
import { Footer } from "./Footer/Footer";
import { useQuery } from "@tanstack/react-query";
import { apiQueries } from "@/api/queries";
import { Spinner } from "@/components/ui/Spinner";
import { Button } from "@/components/ui/Button";

type SetCarIntervalsParams =
  | {
      type: "clean";
      id?: number;
      interval?: number;
    }
  | {
      type: "edit";
      id: number;
      interval: number;
    };

type SetCarIntervals = ({ id, interval, type }: SetCarIntervalsParams) => void;

type SendMapMessage = <T extends CarsMapMethods>(
  methodName: T,
  parameters: Parameters<SendMessage[T]>[0]
) => void;

export const GameContext = createContext({
  setCarIntervals: (() => {}) as SetCarIntervals,
  sendMapMessage: (() => {}) as SendMapMessage,
  setIsParkOpen: (() => {}) as Dispatch<SetStateAction<boolean>>,
  isParkOpen: false,
});

export function GamePage() {
  const {
    unityProvider,
    sendMessage: libSendMessage,
    isLoaded,
    unload,
  } = useUnityContext({
    loaderUrl: "./unity-assets/CarsSimulation.loader.js",
    dataUrl: "./unity-assets/CarsSimulation.data",
    frameworkUrl: "./unity-assets/CarsSimulation.framework.js",
    codeUrl: "./unity-assets/CarsSimulation.wasm",
  });

  const [delCarIntervals, setDelCarIntervals] = useState<
    { id: number; interval: number }[]
  >([]);
  const [isParkOpen, setIsParkOpen] = useState(false);

  const setCarIntervals = ({ id, interval, type }: SetCarIntervalsParams) => {
    if (type === "clean") {
      setDelCarIntervals([]);
      return;
    } else if (type === "edit") {
      if (!delCarIntervals.some((el) => el.id === id)) {
        setDelCarIntervals((prevIntervals) => [
          ...prevIntervals,
          { id, interval },
        ]);
      } else {
        setDelCarIntervals((prevIntervals) =>
          prevIntervals.filter((el) => el.id === id)
        );
      }
    }
  };

  const { data: userCars } = useQuery({
    ...apiQueries.getGameCars({
      page: 1,
      size: 100,
    }),
  });

  function sendMapMessage<T extends CarsMapMethods>(
    methodName: T,
    parameters: Parameters<SendMessage[T]>[0]
  ) {
    libSendMessage("Bridge", methodName, JSON.stringify(parameters));
  }

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    if (userCars) {
      sendMapMessage("RemoveAllCars", "empty");
      if (userCars?.results.some((car) => car.ride_end_time !== null)) {
        sendMapMessage("CreateCars", {
          ids: userCars.results
            .filter((car) => car.ride_end_time !== null)
            .slice(0, 70)
            .map((car) => car.id + 100), // добавляем 100, т.к. меньше 100 и 100 - это для zoom
        });
      }
    }
  }, [isLoaded, userCars]);

  useEffect(() => {
    const intervals: NodeJS.Timeout[] = [];
    delCarIntervals.forEach(({ interval, id }) => {
      const intervalId = setInterval(() => {
        sendMapMessage("RemoveCar", id + 100); // добавляем 100, т.к. меньше 100 и 100 - это для zoom
      }, interval * 1000);

      intervals.push(intervalId);
    });

    return () => {
      intervals.forEach((interval) => clearInterval(interval));
    };
  }, [delCarIntervals]);

  const [scale, setScale] = useState(0);
  const [initialDistance, setInitialDistance] = useState<number | null>(null);

  const zoomIn = () =>
    setScale((prev) => (prev < 0 ? 5 : prev < 15 ? prev + 5 : prev));
  const zoomOut = () =>
    setScale((prev) => (prev > 0 ? -5 : prev > -35 ? prev - 5 : prev));

  const handleWheel = (event: WheelEvent) => {
    event.preventDefault();
    if (event.deltaY > 0) {
      zoomOut();
    } else if (event.deltaY < 0) {
      zoomIn();
    }
  };

  const handleTouchStart = (event: TouchEvent) => {
    if (event.touches.length === 2) {
      setInitialDistance(getDistance(event.touches));
    }
  };

  const handleTouchMove = (event: TouchEvent) => {
    if (event.touches.length === 2 && initialDistance !== null) {
      event.preventDefault();
      const currentDistance = getDistance(event.touches);
      const distanceDiff = currentDistance / initialDistance;

      if (distanceDiff > 1) {
        zoomIn();
      } else {
        zoomOut();
      }
      setInitialDistance(currentDistance);
    }
  };

  const handleTouchEnd = () => {
    setInitialDistance(null);
  };

  const getDistance = (touches: TouchList) => {
    const dx = touches[0].clientX - touches[1].clientX;
    const dy = touches[0].clientY - touches[1].clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };

  useEffect(() => {
    window.addEventListener("wheel", handleWheel);
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);

    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, [scale, initialDistance]);

  useEffect(() => {
    sendMapMessage("AddCar", scale); // метод AddCar со значением < 100 работает для зума!
  }, [scale]);

  return (
    <section>
      <Header unload={unload} isLoaded={isLoaded} />
      <Unity
        className="h-screen w-screen -mx-layout"
        devicePixelRatio={2}
        unityProvider={unityProvider}
      />
      {isLoaded && (
        <div className="absolute top-1/2 translate-y-[-50%] right-2 flex flex-col gap-2.5 w-fit h-fit">
          <Button
            className="text-lg p-2 w-10 h-10"
            onClick={zoomIn}
            disabled={scale >= 15}
          >
            +
          </Button>
          <Button
            className="text-lg p-2 w-10 h-10"
            onClick={zoomOut}
            disabled={scale === 0 || scale <= -20}
          >
            -
          </Button>
        </div>
      )}
      {!isLoaded && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Spinner />
        </div>
      )}
      <GameContext.Provider
        value={{ setCarIntervals, sendMapMessage, setIsParkOpen, isParkOpen }}
      >
        <Footer />
      </GameContext.Provider>
    </section>
  );
}

export type CarsMapMethods =
  | "CreateCars"
  | "AddCar"
  | "RemoveAllCars"
  | "RemoveCar";

export type SendMessage = {
  CreateCars: (data: { ids: number[] }) => void;
  AddCar: (carId: number) => void;
  RemoveAllCars: (empty: "empty") => void;
  RemoveCar: (carId: number) => void;
};
