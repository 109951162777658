import { Link } from "../Link/Link";
// import { MaskIcon } from "../ui/MaskIcon";
import { ROUTE, AppRoute } from "@/config/navigation";
import { useLocation } from "react-router-dom";
import { cn } from "@/utils";
import { useTranslation } from "react-i18next";
import { TranslateNamespaces } from "@/localization/localizationConfig";

export function AppNavigation() {
  const { t } = useTranslation("common");
  const location = useLocation();
  const { isApplePlatform } = usePlatform();

  const locationFirstChunk = location.pathname.split("/")[1] as AppRoute;

  return (
    <>
      <nav
        className={cn(
          "fixed bottom-0 left-0 right-0 flex items-center bg-nav px-layout h-nav z-20",
          {
            "pb-4 h-nav-apple": isApplePlatform,
          }
        )}
      >
        <ul className="flex w-full justify-between font-inter">
          {navList.map(
            ({ path, translateTitleKey, icon: Icon, iconClassName }) => {
              const _isActive = locationFirstChunk === path;
              return (
                <li key={path}>
                  <Link
                    className={cn(
                      "text-gray-middle font-medium font-inter text-xs flex flex-col items-center text-center",
                      {
                        "text-white-light": _isActive,
                      }
                    )}
                    to={path}
                  >
                    <div
                      className={cn(
                        "w-16 h-8 rounded-20 mb-1 text-gray-middle flex items-center justify-center",
                        iconClassName,
                        {
                          "bg-black-dark text-white-light": _isActive,
                        }
                      )}
                    >
                      {Icon}
                    </div>
                    {/* <img
                    src={icon}
                    // iconSize="40%"
                    
                    // iconClassName={cn("bg-gray-middle", iconClassName, {
                    //   "bg-white-light": _isActive,
                    // })}
                  /> */}
                    {t(`nav.${translateTitleKey}`)}
                  </Link>
                </li>
              );
            }
          )}
        </ul>
      </nav>
      <div
        className={cn("h-nav mt-layout", {
          "pb-4 h-nav-apple": isApplePlatform,
        })}
      />
    </>
  );
}

import {
  CarIcon,
  PlayIcon,
  StoreIcon,
  PostsWrenchIcon,
  StrongBoxIcon,
} from "./icons";

import { usePlatform } from "@/hooks/usePlatform";
import { ReactElement } from "react";

const navList: {
  path: AppRoute;
  translateTitleKey: keyof TranslateNamespaces["common"]["nav"];
  icon: ReactElement;
  iconClassName?: string;
}[] = [
  {
    path: ROUTE.PARK,
    translateTitleKey: "park",
    icon: <CarIcon />,
  },
  {
    path: ROUTE.SERVICE_STATION,
    translateTitleKey: "serviceStation",
    icon: <PostsWrenchIcon />,
  },
  {
    path: ROUTE.MARKET,
    translateTitleKey: "market",
    icon: <StoreIcon />,
  },
  {
    path: ROUTE.EXCHANGE,
    translateTitleKey: "exchange",
    icon: <StrongBoxIcon />,
  },
  {
    path: ROUTE.PLAY,
    translateTitleKey: "play",
    icon: <PlayIcon />,
    iconClassName: "text-yellow-primary",
  },
];
