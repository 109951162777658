import { apiQueries } from "@/api/queries";
import { SideBar } from "@/components/sideBar/SideBar";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { Head } from "@/components/ui/Head";
import { UserCars } from "./UserCars";
import { ActionsWithAll } from "./ActionsWithAll";
import { TransferTOBalance } from "./TransferToBalance";
import { SuccessMutationProvider } from "./SuccessMutationContext";
import { GameEarnings } from "./GameEarnings";
import { GameContext } from "../GamePage";

export function ParkSidebar() {
  const { t } = useTranslation("common");

  const { isParkOpen, setIsParkOpen } = useContext(GameContext);

  const [isTransferOpen, setIsTransferOpen] = useState(false);

  const { data: gameStatistic } = useQuery(apiQueries.getGameStatistics());

  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    ...apiQueries.getGameCarsInfinity(),
    initialPageParam: 1,
    getNextPageParam: (lastPageData, _, lastPageParam: number) => {
      return lastPageData.next ? lastPageParam + 1 : undefined;
    },
    refetchOnMount: true,
    enabled: isParkOpen,
  });

  const userCars = {
    results: data?.pages.flatMap((page) => page.results) ?? [],
    count: data?.pages[0]?.count ?? 0,
    next: data?.pages[data?.pages.length - 1]?.next ?? null,
    previous: data?.pages[data?.pages.length - 1]?.previous ?? null,
  };

  return (
    <SuccessMutationProvider setIsTransferOpen={setIsTransferOpen}>
      <SideBar isOpen={isParkOpen && !isTransferOpen} setIsOpen={setIsParkOpen}>
        <Head as={"h2"} className={"text-center mt-3 mb-5"}>
          {t("game.footer.park")}
        </Head>

        <GameEarnings
          earnings={Number(gameStatistic?.earnings) ?? 0}
          setIsTransferOpen={setIsTransferOpen}
        />
        <UserCars
          userCars={userCars}
          fetchNextPage={fetchNextPage}
          isFetching={isFetching}
          hasNextPage={hasNextPage}
        />
        <ActionsWithAll />
      </SideBar>

      <TransferTOBalance
        isTransferOpen={isTransferOpen}
        setIsTransferOpen={setIsTransferOpen}
        earnings={Number(gameStatistic?.earnings) ?? 0}
      />
    </SuccessMutationProvider>
  );
}
