import { Button } from "@/components/ui/Button";
import { useTranslation } from "react-i18next";
import background from "@/assets/tiers-sidebar.webp";
import imgTransaction from "@/assets/profile/transaction-in-process.webp";
import imgRequest from "@/assets/profile/request-in-process.webp";
import { ROUTE } from "@/config/navigation";
import { useNavigate } from "react-router-dom";
import { PaymentType } from "@/api";

export function TransactionResult(p: {
  type: PaymentType;
  setIsOpen: (value: boolean) => void;
}) {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  return (
    <div className="text-center">
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
        }}
        className="w-full h-44 bg-no-repeat flex items-center justify-center"
      >
        <div
          style={{
            backgroundImage: `url(${
              p.type === "withdrawal" ? imgRequest : imgTransaction
            })`,
          }}
          className={"size-32 bg-no-repeat bg-contain bg-center rounded-full"}
        ></div>
      </div>
      <div className="px-3">
        <p className="font-montserrat font-black text-lg italic mb-1">
          {p.type === "withdrawal"
            ? t("balance.transfer.requestIsProcessed")
            : t("balance.refill.transactionInProcess")}
        </p>
        <p className="w-5/6 mx-auto text-sm">
          {p.type === "withdrawal"
            ? t("balance.transfer.processingTime")
            : t("balance.refill.transactionTime")}
        </p>
        <div className="flex item-center justify-between gap-3 mt-9 mb-5">
          <Button className="w-full" onClick={() => p.setIsOpen(false)}>
            {t("balance.transfer.ok")}
          </Button>
          {p.type === "deposit" && (
            <Button
              variant="dark"
              className="w-full"
              onClick={() => {
                p.setIsOpen(false);
                navigate(ROUTE.TRANSACTIONS);
              }}
            >
              {t("profile.links.transactions")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
