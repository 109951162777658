import { ResponseGetParkCarInfo } from "@/api";
import { apiMutations, apiQueries, QueryKey } from "@/api/queries";
import { Head } from "@/components/ui/Head";
import { useMutation, useQuery, keepPreviousData } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { CollectionCard } from "./CollectionCard";
import { Button } from "@/components/ui/Button";
import { useState } from "react";
import { SideBar } from "@/components/sideBar/SideBar";
import { STICKER_INCREASE_PERCENT } from "@/variables";
import { useInvalidateQueriesByKeys } from "@/api/apiUtils";
import { useImagePreload } from "@/hooks/useImagePreload";
import { Spinner } from "@/components/ui/Spinner";

const SIZE = 5;

export function CarsCollection(p: { carInfo: ResponseGetParkCarInfo }) {
  const { t } = useTranslation("common");

  const [size, setSize] = useState(SIZE);

  const [stickerOpenId, setStickerOpenId] = useState<number | null>(null);
  const { mutateAsync: addSticker, isPending } = useMutation(
    apiMutations.addSticker
  );

  useImagePreload(p.carInfo?.cover_with_sticker?.file, {
    enabled: Boolean(p.carInfo?.cover_with_sticker),
  });

  const collection = useQuery({
    ...apiQueries.getParkCarsCollection({
      id: Number(p.carInfo.id),
      params: {
        page: 1,
        size,
        ordering: "create_date",
      },
    }),
    placeholderData: keepPreviousData,
  });

  const { data } = collection;

  const { userProfile } = useInvalidateQueriesByKeys([QueryKey.userProfile]);
  const { parkCarsCollection } = useInvalidateQueriesByKeys([
    QueryKey.parkCarsCollection,
  ]);

  async function addStickerHandler(e: React.MouseEvent<HTMLButtonElement>) {
    if (e.target) e.currentTarget.disabled = true;

    await addSticker(+stickerOpenId!);
    parkCarsCollection.invalidate();
    userProfile.invalidate();
    setStickerOpenId(null);
  }

  return (
    <>
      <div className="mt-5">
        <Head className="mb-2 text-base">
          {t("car.collection")} {p.carInfo.title} (
          {p.carInfo.current_count_per_user}/
          {p.carInfo.max_count_per_user ?? <>&#8734;</>})
        </Head>

        <ul>
          {data?.results?.map(
            ({ cover, id, car, is_broken, is_stickered, sequence_id }) => (
              <li key={id} className="mb-3">
                <CollectionCard
                  id={id}
                  title={car.title}
                  cover={cover.file}
                  isBroken={is_broken}
                  isStickered={is_stickered}
                  sequenceId={sequence_id}
                  setStickerOpenId={setStickerOpenId}
                />
              </li>
            )
          )}
        </ul>
        {data?.next && (
          <Button
            onClick={() => {
              setSize(size + SIZE);
            }}
            className="w-full bg-black-footer text-yellow-primary border border-yellow-primary py-2"
          >
            {t("actions.showMore")}
          </Button>
        )}
        {!data?.next && data?.count && data?.count > SIZE && (
          <Button
            onClick={() => {
              setSize(SIZE);
            }}
            className="w-full bg-black-footer text-yellow-primary border border-yellow-primary py-2"
          >
            {t("actions.showLess")}
          </Button>
        )}
      </div>

      <SideBar
        isOpen={!!stickerOpenId}
        setIsOpen={() => setStickerOpenId(null)}
      >
        <div className="text-center mt-3">
          <Head className="mb-2">{t("car.sticker")}</Head>
          <p className="text-gray-dark px-4 text-sm">
            {t("actions.addSticker", {
              percents: STICKER_INCREASE_PERCENT,
            })}
          </p>
          <img
            src={p.carInfo.cover_with_sticker!.file}
            alt={p.carInfo.title}
            className="w-full aspect-[33/20] object-contain border border-gray-border border-opacity-15 rounded-20 mt-4 mb-6"
          />
          <Button onClick={addStickerHandler} className="w-full flex justify-center" variant="dark">
            {isPending ? (
              <Spinner size="small" />
            ) : (
              t("actions.stickFor", {
                price: p.carInfo.sticker_cost,
              })
            )}
          </Button>
        </div>
      </SideBar>
    </>
  );
}
