import { imgOnboardingMobile, imgOnboardingFull } from "@/assets";

import { useTranslation } from "react-i18next";

export const Onboarding = () => {
  const { t } = useTranslation("common");

  const innerWith = window.innerWidth;
  const innerHeight = window.innerHeight;

  const scale = innerHeight / innerWith;

  return (
    <div
      className="w-full h-full fixed top-0 right-0 bg-no-repeat bg-cover bg-top z-50 flex items-end justify-center pb-12"
      style={{
        backgroundImage: `url(${
          scale > 1.6 ? imgOnboardingMobile : imgOnboardingFull
        })`,
      }}
    >
      <p className={"font-montserrat font-black text-lg text-white"}>
        {t("onboarding.loading")}...
      </p>
    </div>
  );
};
