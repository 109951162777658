export const ROUTER_PARAMS = {
  CAR_ID: "carId",
} as const;

export type RouterParams = (typeof ROUTER_PARAMS)[keyof typeof ROUTER_PARAMS];

export const ROUTE = {
  PARK: "park",
  CAR_INFO: `park/:${ROUTER_PARAMS.CAR_ID}`,
  SERVICE_STATION: "service-station",
  MARKET: "market",
  MARKET_CAR_INFO: `market/:${ROUTER_PARAMS.CAR_ID}`,
  EXCHANGE: "exchange",
  PLAY: "play",
  PROFILE: "profile",
  REFS: "refs",
  TRANSACTIONS: "transactions",
  TASKS: "tasks",
  BALANCE: "balance",
  RATING: "rating",
  POLITIC: "politic",
  TERMS: "terms",
  WALLET: "wallet",
} as const;

export function generateDynamicRoute(route: AppRoute, chunk: string | number) {
  return route.replace(new RegExp(":.+/?"), String(chunk));
}

export type AppRoute = (typeof ROUTE)[keyof typeof ROUTE];
