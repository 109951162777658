import {
  CURRENCY,
  dayFormatter,
  weekFormatter,
  monthFormatter,
  yearFormatter,
  allTimeFormatter,
  getDayTootlipText,
  getWeekTootlipText,
  getMonthTootlipText,
  getYearTootlipText,
  getAllTimeTootlipText,
  getLocalizedFormatter,
  LocalizedFormatterFn,
} from "@/components/pages/exchange/utils";
import { cn } from "@/utils";
import { ExchangesInterval } from "@/api";
import { useMemo, useState } from "react";
import { apiQueries } from "@/api/queries";
import tonSvg from "@/assets/coin-txy-66.webp";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Image } from "@telegram-apps/telegram-ui";
import { Lang } from "@/localization/localizationConfig";
import Chart from "@/components/pages/exchange/Chart/Chart";
import { ExchangeAtTime } from "@/components/pages/exchange/types";

const TOOLTIP_FORMATTER_BY_PERIOD: Record<
  ExchangesInterval,
  LocalizedFormatterFn
> = {
  day: getDayTootlipText,
  week: getWeekTootlipText,
  month: getMonthTootlipText,
  year: getYearTootlipText,
  all: getAllTimeTootlipText,
};

const FORMATTER_BY_PERIOD: Record<ExchangesInterval, LocalizedFormatterFn> = {
  day: dayFormatter,
  week: weekFormatter,
  month: monthFormatter,
  year: yearFormatter,
  all: allTimeFormatter,
};

const PAGE_SIZE_BY_PERIOD: Record<ExchangesInterval, number> = {
  day: 50,
  week: 7,
  month: 31,
  year: 12,
  all: 100,
};

const filterNulls = (arr: ExchangeAtTime[] | undefined) =>
  arr?.filter(
    (v) => (v.rate !== null && Number(v.rate) === 0) || !!Number(v.rate)
  ) || [];

export const ExchangePage = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");

  const [chartPeriod, setChartPeriod] = useState<ExchangesInterval>("year");

  const { data: currentRate } = useQuery(apiQueries.getCurrentRate);
  const { data: exchangeData, isLoading } = useQuery(
    apiQueries.getExchanges({
      interval: chartPeriod,
      page: 1,
      size: PAGE_SIZE_BY_PERIOD[chartPeriod],
    })
  );

  const periodNames: Partial<Record<ExchangesInterval, string>> = useMemo(
    () => ({
      day: t("exchange.periods.day"),
      week: t("exchange.periods.week"),
      month: t("exchange.periods.month"),
      year: t("exchange.periods.year"),
    }),
    []
  );

  const xAsixFormatter = useMemo(
    () =>
      getLocalizedFormatter(
        FORMATTER_BY_PERIOD[chartPeriod],
        language as Lang,
        t
      ),
    [chartPeriod, language]
  );

  const tooltipFormatter = useMemo(
    () =>
      getLocalizedFormatter(
        TOOLTIP_FORMATTER_BY_PERIOD[chartPeriod],
        language as Lang,
        t
      ),
    [chartPeriod, language]
  );

  return (
    <>
      <div className="bg-black-dark-card rounded-10 border-gray-border border px-4 py-3 mt-5">
        <p className="text-gray-light mb-1.5 text-sm">
          {t("exchange.current-rate") + ":"}
        </p>
        <div className="flex items-end gap-2">
          <Image size={24} src={tonSvg} style={{ borderRadius: "50%" }} />
          <p className="text-base font-montserrat font-black italic tracking-wider">{`1 ${CURRENCY} = ${
            currentRate?.usdt_per_txy || "?"
          } USDT`}</p>
        </div>
      </div>

      <div className="flex gap-3 mt-5 mb-3 flex-wrap">
        {(Object.keys(periodNames) as ExchangesInterval[]).map((periodID) => (
          <button
            key={periodID}
            onClick={() => setChartPeriod(periodID)}
            className={cn(
              "text-xs text-gray-middle py-1 px-2 text-nowrap tracking-tight rounded-6",
              { "bg-yellow-primary text-black-dark": periodID === chartPeriod }
            )}
          >
            {periodNames[periodID]}
          </button>
        ))}
      </div>

      <Chart
        key={chartPeriod}
        isLoading={isLoading}
        formatter={xAsixFormatter}
        tooltipFormatter={tooltipFormatter}
        data={filterNulls(exchangeData?.results)}
      />
    </>
  );
};
